<div class="menu-container">
    <!--div class="user-menu" *ngIf="user">
        <div class="user-data" routerLink="users/dashboard">
            <div class="user-icon">A</div>
            <div class="user-name">
                <div>{{ user.given_name }} {{ user.family_name }}</div>
                <div>{{ user.email }}</div>
            </div>
            <mat-icon>chevron_right</mat-icon>
        </div>
        <div class="user-location">
            <a routerLink="intro" routerLinkActive="active">
                <mat-icon>interests</mat-icon>
                Home
            </a>
            <a routerLink="users/canvas" routerLinkActive="active">
                <mat-icon>polyline</mat-icon>
                Journey
            </a>
            <a routerLink="tools/concierge" routerLinkActive="active">
                <mat-icon>room_service</mat-icon>
                Services
            </a>
            <a routerLink="suche" routerLinkActive="active">
                <mat-icon>cottage</mat-icon>
                Houses
            </a>
        </div>
    </div-->

    <a class="show-on-mobile" *ngIf="user" [routerLink]="['/users/canvas']">Euer Profil</a>
    <!--    <a href="https://www.euerzuhause.de/#so-funktionierts">SO FUNKTIONIERT´S</a>-->
    <a [routerLink]="['/suche/slim']">Häuser</a>
    <a routerLink="companies">Baupartner</a>
    <div>
        <input type="checkbox" id="planen-menu-show" />
        <label for="planen-menu-show">
            Planen
            <mat-icon>keyboard_arrow_up</mat-icon>
        </label>
        <div class="menu-panel">
            <div class="section-container" *ngFor="let sectionContainer of planenLinks">
                <div class="section" *ngFor="let section of sectionContainer">
                    <a class="section-title" [href]="section.url">{{ section.name }}</a>
                    <div class="section-links">
                        <a *ngFor="let link of section.links" [href]="link.url">{{ link.title }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
        <input type="checkbox" id="bauen-menu-show" />
        <label for="bauen-menu-show">
            Bauen
            <mat-icon>keyboard_arrow_up</mat-icon>
        </label>
        <div class="menu-panel">
            <div class="section-container" *ngFor="let sectionContainer of bauenLinks">
                <div class="section" *ngFor="let section of sectionContainer">
                    <a class="section-title" [href]="section.url">{{ section.name }}</a>
                    <div class="section-links">
                        <a *ngFor="let link of section.links" [href]="link.url">{{ link.title }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--    <a href="https://www.euerzuhause.de/#faq">FAQ</a>-->
    <!--    <a href="https://www.euerzuhause.de/vergleichsangebot">VERGLEICHSANGEBOT</a>-->
    <div class="position-relative">
        <input type="checkbox" id="services-menu-show" />
        <label for="services-menu-show">
            Services
            <mat-icon>keyboard_arrow_up</mat-icon>
        </label>
        <div class="menu-panel services">
            <div class="section-container" *ngFor="let sectionContainer of servicesLinks">
                <div class="section" *ngFor="let section of sectionContainer">
                    @if (section.url) {
                        <a class="section-title" [href]="section.url">{{ section.name }}</a>
                    } @else {
                        <span class="section-title">{{ section.name }}</span>
                    }
                    <div class="section-links" *ngFor="let link of section.links">
                        <ng-container>
                            @if (link.local) {
                                <a [routerLink]="link.url">{{ link.title }}</a>
                            } @else {
                                <a [href]="link.url">{{ link.title }}</a>
                            }
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!user" class="login-button show-on-mobile" (click)="login.emit()">Anmelden</div>
</div>
