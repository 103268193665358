<app-modal id="zip-code-prompt">
    <div class="modal-container">
        <div class="title">{{ 'shared.zipcode-prompt.title' | translate }}</div>
        <div class="message">{{ 'shared.zipcode-prompt.description' | translate }}</div>
        <div class="inputs">
            <div class="input-with-spinner">
                <mat-form-field appearance="outline" color="accent" class="white">
                    <mat-label>PLZ Bauort</mat-label>
                    <input
                        class="zip-input"
                        matInput
                        autocomplete="off"
                        ngx-gp-autocomplete
                        [options]="{
                            componentRestrictions: { country: 'de' },
                            fields: ['address_components'],
                            strictBounds: false,
                            types: ['postal_code']
                        }"
                        [ngModel]="zip['postalCode'] && zip['postalCode'] + ' ' + zip['postalCodeCity']"
                        (onAddressChange)="codeChange($event)"
                        (keydown)="zip['postalCode'] = ''"
                        [disabled]="inputFieldLoading"
                    />
                </mat-form-field>
                <div class="icon-container">
                    <mat-spinner diameter="20" *ngIf="inputFieldLoading"></mat-spinner>
                    <mat-icon *ngIf="zip['postalCode'] && !inputFieldLoading" (click)="zip['postalCode'] = ''; zip['postalCodeCity'] = ''"
                        >clear
                    </mat-icon>
                </div>
            </div>
        </div>
        <div class="buttons">
            <button color="accent" (click)="cancelCallback()" mat-stroked-button type="button">
                {{ 'generic.cancel' | translate }}
            </button>
            <button
                color="primary"
                (click)="zipCallback()"
                mat-flat-button
                type="button"
                [disabled]="!zip['postalCodeCity'] && !zip['postalCode']"
            >
                {{ 'generic.continue' | translate }}
            </button>
        </div>
    </div>
</app-modal>
