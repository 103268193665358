import { MatPaginatorIntl } from '@angular/material/paginator';

const rangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
        return ``;
    }

    length = Math.max(length, 0);

    return `${page + 1} / ${Math.ceil(length / pageSize)}`;
};

export function getMatPaginatorIntl() {
    const paginatorIntl = new MatPaginatorIntl();

    paginatorIntl.itemsPerPageLabel = 'Zeilen pro Seite:';
    paginatorIntl.nextPageLabel = 'Nächste Seite';
    paginatorIntl.previousPageLabel = 'Vorherige Seite';
    paginatorIntl.lastPageLabel = 'Letzte Seite';
    paginatorIntl.firstPageLabel = 'Erste Seite';
    paginatorIntl.getRangeLabel = rangeLabel;

    return paginatorIntl;
}
