import { Injectable } from '@angular/core';
import { JourneyStagesService } from '../_services/journey-stages.service';
import { JourneyStepsService } from '../_services/journey-steps.service';
import { UserDataService } from '../_services/user-data.service';
import { forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Journey, JourneyStage, JourneyStep } from '../_models/journey';
import { UserData } from '../_models/user';
import { UserService } from '../_services/user.service';
import { LocalStorageService } from '../_services/local-storage.service';

@Injectable({
    providedIn: 'root',
})
export class JourneyBpService {
    constructor(
        private journeyStagesService: JourneyStagesService,
        private journeyStepsService: JourneyStepsService,
        private userDataService: UserDataService,
        private userService: UserService
    ) {
        this.userService.onLogout.subscribe(() => {
            this.removeLocalData();
            this.loadUserJourney().subscribe();
        });
    }

    static localStorageKey = 'journeyUserData';
    journey!: Journey;

    loadUserJourney() {
        const userLoggedIn = this.userService.isLoggedIn();
        const localUserData = new UserData(LocalStorageService.get(JourneyBpService.localStorageKey));
        const userDataRequest = userLoggedIn ? this.userDataService.get() : of(localUserData);

        return forkJoin([this.journeyStagesService.get(), this.journeyStepsService.get(), userDataRequest]).pipe(
            map((data: [JourneyStage[], JourneyStep[], UserData]) => {
                let userData = data[2];
                const shouldSync = !!(userLoggedIn && !userData.journeyStage && localUserData.journeyStage);

                if (!userData.journeyStage) {
                    userData = localUserData;
                }

                const journey = new Journey({
                    journeyStages: data[0],
                    journeySteps: data[1],
                    userData,
                });
                this.journey = journey;

                if (shouldSync) {
                    this.saveJourney().subscribe();
                }

                return journey;
            })
        );
    }

    getJourney() {
        return this.journey;
    }

    saveJourney() {
        LocalStorageService.set(JourneyBpService.localStorageKey, this.journey.userData);
        if (this.userService.isLoggedIn()) {
            return this.userDataService.patch(this.journey.userData);
        } else {
            return of(this.journey.userData);
        }
    }

    isNewJourney() {
        return !Object.keys(LocalStorageService.get(JourneyBpService.localStorageKey)).length;
    }

    removeLocalData() {
        LocalStorageService.removeItem(JourneyBpService.localStorageKey);
    }
}
