import { BaseModel } from 'src/app/_common/base-model';
import { PaginationInterface } from 'src/app/_common/interface/pagination.interface';
import { Questionnaire, QuestionnaireAnswers } from 'src/app/_models/questionnaire';
import { Attribute, HouseAttributeName, OperationType, SelectedCompany } from 'src/app/_models/matches-summary';
import { merge, minBy } from 'lodash-es';
import { getPathFromURL, getYouTubeVideIdFromLink } from 'src/app/_common/app-utils';
import { Params } from '@angular/router';

export enum ImageType {
    cover = 'cover',
    exterior = 'exterior',
    interior = 'interior',
    other = 'other',
}

export enum FloorplanType {
    floor_0 = 'floor_0',
    floor_1 = 'floor_1',
    floor_2 = 'floor_2',
}

export enum ImageFileType {
    typeImage = 'typeImage',
    typePdf = 'typePdf',
}

export enum DistributionStatus {
    LOW_QUALITY_USER_TYPE = 'LOW_QUALITY_USER_TYPE',
    LOW_QUALITY_USER_B2B_CONTACT_REQUESTED = 'LOW_QUALITY_USER_B2B_CONTACT_REQUESTED',
    NEW_EMAIL_FOR_THE_COMPANY = 'NEW_EMAIL_FOR_THE_COMPANY',
    EMAIL_ALREADY_KNOWN_TO_COMPANY = 'EMAIL_ALREADY_KNOWN_TO_COMPANY',
    MAX_COMPANIES_PER_EMAIL_EXCEEDED = 'MAX_COMPANIES_PER_EMAIL_EXCEEDED',
}

export class Leads extends BaseModel implements PaginationInterface {
    constructor(leads: Leads) {
        super(leads);
        this.docs = this.docs.map((lead) => new Lead(lead));
    }

    docs!: Lead[];

    getUniqueNames(): string[] {
        return [...new Set(this.docs.map((val) => val.company?.name || ''))];
    }

    wasContactRequestSent(): boolean {
        return !!this.docs.find((lead) => lead.wasSent());
    }
}

export class Lead extends BaseModel {
    constructor(lead: Lead) {
        super(lead);
        this.house = new House(this.house);
        this.company = new Company(this.company);
        merge(this.questionnaire, this.answers);
        this.questionnaire = new Questionnaire(this.questionnaire);
        this.answers = new QuestionnaireAnswers(this.answers);
    }

    id!: string;
    matchId!: string;
    questionnaire!: Questionnaire;
    company!: Company;
    answers!: QuestionnaireAnswers;
    house!: House;
    user!: User;
    distributionStatus!: DistributionStatus;
    algorithm!: string;
    score!: number;
    createdAt!: string;
    updatedAt!: string;

    getRequestStatus() {
        switch (this.distributionStatus) {
            case DistributionStatus.LOW_QUALITY_USER_TYPE:
                return 'notSent';
            case DistributionStatus.LOW_QUALITY_USER_B2B_CONTACT_REQUESTED:
                return 'sent';
            default:
                return undefined;
        }
    }

    wasSent(): boolean {
        return (
            this.distributionStatus === DistributionStatus.NEW_EMAIL_FOR_THE_COMPANY ||
            this.distributionStatus === DistributionStatus.LOW_QUALITY_USER_B2B_CONTACT_REQUESTED
        );
    }
}

export class RegionalOffice extends BaseModel {
    id?: string;
    name!: string;
    logoUrl!: string;
    salesAreas?: string;
    salesRepresentatives?: {
        id: string;
        name: string;
        calComPublicLink?: string;
        calComLeadLink?: string;
        zipcodes?: string[];
    }[];
    isPlotMandatory?: boolean;
    foundationIncluded?: boolean;
    kitchenInteriorIncluded?: boolean;
    calComPublicLink?: string;
    calComLeadLink?: string;

    getCalendarPublicMeetingPath(userZip: string): string | undefined {
        const matchingSalesRep = this.salesRepresentatives?.find((salesRep) => salesRep.zipcodes?.includes(userZip));

        return matchingSalesRep?.calComPublicLink
            ? getPathFromURL(matchingSalesRep.calComPublicLink)
            : this.calComPublicLink
              ? getPathFromURL(this.calComPublicLink)
              : undefined;
    }

    getCalendarUserMeetingPath(userZip: string): string | undefined {
        const matchingSalesRep = this.salesRepresentatives?.find((salesRep) => salesRep.zipcodes?.includes(userZip));

        return matchingSalesRep?.calComLeadLink
            ? getPathFromURL(matchingSalesRep.calComLeadLink)
            : this.calComLeadLink
              ? getPathFromURL(this.calComLeadLink)
              : undefined;
    }
}

export class Company extends BaseModel {
    id!: string;
    slug?: string;
    foundationIncluded!: boolean;
    constructionType!: 'PREFABRICATED' | 'SOLID';
    isPlotMandatory!: boolean;
    kitchenInteriorIncluded!: boolean;
    name!: string;
    displayName?: string;
    logoUrl!: string;
    bookPublicMeetingLink?: string;
    bookUserMeetingLink?: string;
    salesAreas?: string;
    warranty?: string;
    fixedPriceCommitment?: boolean;
    certKfnQng?: string;
    individualPlanning?: boolean;
    buildingApplicationIncluded?: boolean;
    certificationsMemberships?: string[];
    heroImage1?: string;
    heroImage2?: string;
    heroImage3?: string;
    descriptions?: {
        label: string;
        description: string;
    }[];
    videos?: {
        link: string;
    }[];
    certificates?: {
        name: string;
        logoImage: string;
    }[];
    reviews?: {
        name: string;
        text: string;
    }[];
    foundingYear?: number;
    totalEmployees?: number;
    headquarter?: string;
    totalHousesBuilt?: number;
    yearlyHousesBuilt?: number;
    services?: CompanyServices[];
    modelHouses?: {
        id: string;
        name: string;
        address: string;
        openDays: string;
        openTime: string;
        meetingLink: string;
    }[];
    expansionStages?: string[];
    regionalOffices?: RegionalOffice[];
    rankingScore?: number;

    attributes!: {
        advantages: string[];
        ezhComments: string[];
        specials: string[];
    };

    getCompanyServicesNames() {
        return this.services?.map((service) => companyServiceNames[service]);
    }

    getCompanyVideoIds() {
        return this.videos?.map((video) => getYouTubeVideIdFromLink(video.link));
    }

    getLogoPath(): string {
        return this.logoUrl && new URL(this.logoUrl).pathname;
    }

    getCalendarPublicMeetingPath(): string | undefined {
        return getPathFromURL(this.bookPublicMeetingLink);
    }

    getCalendarUserMeetingPath(): string | undefined {
        return getPathFromURL(this.bookUserMeetingLink);
    }

    getDisplayName(): string {
        return this.displayName ?? this.name;
    }

    getAsSelectedType(): SelectedCompany {
        return {
            companyId: this.id,
            regionalOfficeId: this.regionalOffices?.[0].id,
        };
    }

    getDisplayAttributes(): string[] {
        return Object.values(CompanyAttributeName).filter((attributeName) => this[attributeName]);
    }
}

export enum CompanyAttributeName {
    foundationIncluded = 'foundationIncluded',
    kitchenInteriorIncluded = 'kitchenInteriorIncluded',
}

export enum CompanyServices {
    FIXED_PRICE = 'FIXED_PRICE',
    INDIVIDUAL_PLANNING = 'INDIVIDUAL_PLANNING',
    BUILDING_APPLICATION = 'BUILDING_APPLICATION',
    CONSTRUCTION_TIME_GUARANTEE = 'CONSTRUCTION_TIME_GUARANTEE',
    FINANCING_ADVICE = 'FINANCING_ADVICE',
    BLOWER_DOOR_TEST = 'BLOWER_DOOR_TEST',
}

export const companyServiceNames = {
    [CompanyServices.FIXED_PRICE]: 'Preisgarantie',
    [CompanyServices.INDIVIDUAL_PLANNING]: 'Individuelle Planung',
    [CompanyServices.BUILDING_APPLICATION]: 'Bauantrag inklusive',
    [CompanyServices.CONSTRUCTION_TIME_GUARANTEE]: 'Bauzeit Garantie',
    [CompanyServices.FINANCING_ADVICE]: 'Finanzierungsberatung',
    [CompanyServices.BLOWER_DOOR_TEST]: 'Blower Door Test',
};

export class House extends BaseModel {
    constructor(house: House, defaultRegionalOfficeId?: string) {
        super(house);
        this.images = this.images?.map((image) => new Image(image));
        this.company = new Company(this.company);
        this.setDefaultRegionalOffice(defaultRegionalOfficeId);
    }

    id!: string;
    bookmarkRegionalOfficeId?: string;
    companyId!: string;
    company?: Company;
    images!: Image[];
    name!: string;
    description!: string;
    houseCode?: string;
    houseType!: string;
    houseStyle!: string;
    houseState!: string;
    price!: number;
    roofStyle!: string;
    energyEfficiency!: string;
    isBarrierFree!: boolean;
    isCarportAvailable!: boolean;
    isSolarSystemAvailable!: boolean;
    isVentilationSystemAvailable!: boolean;
    isAirConditioningAvailable!: boolean;
    totalLivingArea!: number;
    totalFlexrooms!: number;
    numberOfWCs!: number;
    totalRooms!: number;
    numberOfFloors!: number;
    numberOfBedrooms!: number;
    numberOfWorkingRooms!: number;
    numberOfBathrooms!: number;
    numberOfBathroomsGuest!: number;
    numberOfToilets!: number;
    hasDressingRoom!: boolean;
    hasLaundryRoom!: boolean;
    hasEnSuite!: boolean;
    hasOpenLivingRoom!: boolean;
    hasOpenKitchen!: boolean;
    hasUtilityRoom!: boolean;
    hasStoreRoom!: boolean;
    hasOfficeRoom!: boolean;
    hasTechnicalRoom!: boolean;
    hasBalcony!: boolean;
    hasPatio!: boolean;
    createdAt?: string;
    updatedAt?: string;
    slug?: string;
    regionalHouses?: {
        regionalOfficeId: string;
        name?: string;
        price: number;
    }[];
    defaultRegionalOfficeId?: string;

    setDefaultRegionalOffice(regionalOfficeId?: string) {
        if (regionalOfficeId && this.regionalHouses?.find((regionalHouse) => regionalHouse.regionalOfficeId === regionalOfficeId)) {
            this.defaultRegionalOfficeId = regionalOfficeId;
        } else {
            this.defaultRegionalOfficeId = minBy(this.regionalHouses, 'price')?.regionalOfficeId;
        }
        return this;
    }

    getDefaultPrice(): number {
        const regionalHouse = this.regionalHouses?.find((house) => house.regionalOfficeId === this.defaultRegionalOfficeId);
        return regionalHouse?.price ?? this.price;
    }

    getDefaultName(): string {
        const regionalHouse = this.regionalHouses?.find((house) => house.regionalOfficeId === this.defaultRegionalOfficeId);
        return regionalHouse?.name ?? this.name;
    }

    getDisplayTotalLivingArea() {
        return Math.round(this.totalLivingArea || 0);
    }

    getCoverImage(): Image | undefined {
        return this.images?.find((image) => image.type === ImageType.cover);
    }

    getExteriorImages(): Image[] {
        return this.images?.filter((image) => image.type === ImageType.exterior);
    }

    getInteriorImages(): Image[] {
        return this.images?.filter((image) => image.type === ImageType.interior);
    }

    getFloorplanImages(): Image[] {
        return this.images?.filter(
            (image) => image.type === FloorplanType.floor_0 || image.type === FloorplanType.floor_1 || image.type === FloorplanType.floor_2
        );
    }

    getImages(): Image[] {
        const allImages: Image[] = [];
        const coverImage = this.getCoverImage();
        if (coverImage) {
            allImages.push(coverImage);
        }
        if (this.getExteriorImages() && this.getExteriorImages().length) {
            allImages.push(...this.getExteriorImages());
        }
        if (this.getInteriorImages() && this.getInteriorImages().length) {
            allImages.push(...this.getInteriorImages());
        }
        return allImages;
    }

    getFloorplanPdfs(): Image[] {
        return this.images.filter((image) => image.type === ImageType.other);
    }

    getImagesAndFloorplans(): Image[] {
        return [...this.getImages(), ...this.getFloorplanImages()];
    }

    getImageByFileType(fileType: ImageFileType): Image[] {
        return this.images.filter((image) => image.getFileType() === fileType);
    }

    getDisplayAttributes(attributesToCheck?: (keyof typeof HouseAttributeName)[]): Attribute[] {
        return Object.values(attributesToCheck || HouseAttributeName)
            .filter((attributeName) => this[attributeName])
            .map(
                (attributeName) =>
                    new Attribute({
                        attributeName,
                        attributeValue: this[attributeName],
                        operationType: OperationType.equal,
                    })
            );
    }

    getDefaultHouseLink(): { commands: string[]; queryParams: Params } {
        return {
            commands: ['/suche/', this.slug ?? this.id],
            queryParams: this.defaultRegionalOfficeId ? { regionalOfficeId: this.defaultRegionalOfficeId } : {},
        };
    }
}

export class Image extends BaseModel {
    static typeImage = ImageFileType.typeImage;
    static typePdf = ImageFileType.typePdf;
    type!: string;
    url!: string;

    getPath(): string {
        return new URL(this.url).pathname;
    }

    getFileType(): string {
        return this.url.slice(-3).toLowerCase() === 'pdf' ? Image.typePdf : Image.typeImage;
    }
}

export interface User {
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
}
