import { Injectable } from '@angular/core';
import { ApiDataService } from '../_abstract/api-data.service';
import { DataService } from './data.service';
import { Observable } from 'rxjs';
import { JourneyStage } from '../_models/journey';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class JourneyStagesService extends ApiDataService {
    constructor(dataService: DataService) {
        super(dataService, `journey-stages`);
    }

    get(): Observable<JourneyStage[]> {
        return super.get().pipe(map((journeyStages) => journeyStages.map((journeyStage: JourneyStage) => new JourneyStage(journeyStage))));
    }
}
