import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiDataService } from 'src/app/_abstract/api-data.service';
import { QueryParams } from 'src/app/_common/helper/query-params';
import { Houses } from 'src/app/_models/houses';
import { House } from 'src/app/_models/leads';
import { DataService } from 'src/app/_services/data.service';

@Injectable({
    providedIn: 'root',
})
export class HousesService extends ApiDataService {
    constructor(dataService: DataService) {
        super(dataService, `houses`);
    }

    static listTypeComparison = 'COMPARISON';
    static listTypeBookmarks = 'BOOKMARKS';

    favourites: { houseId: string; regionalOfficeId?: string }[] = [];
    compare: { houseId: string; regionalOfficeId?: string }[] = [];

    get(queryParams = new QueryParams()): Observable<Houses> {
        return super.get(queryParams).pipe(map((response) => new Houses(response)));
    }

    // Temporary solution, as backend is not guarantee the order of return elements
    getByIdsKeepOrder(idList: { houseId: string; regionalOfficeId?: string }[]): Observable<Houses> {
        const houseId = idList.map((id) => id.houseId);
        return super.get(new QueryParams({ houseId })).pipe(
            map((response) => {
                const houses = new Houses(response);

                houses.docs.sort((a, b) => {
                    return houseId.indexOf(a.id) - houseId.indexOf(b.id);
                });
                for (const [index, house] of houses.docs.entries()) {
                    // same order as input ids, therefore can use the index directly without find()
                    house.setDefaultRegionalOffice(idList[index].regionalOfficeId);
                }
                return houses;
            })
        );
    }

    getByIdentifier(identifier: string, regionalOfficeId?: string): Observable<House> {
        return super.get(new QueryParams(), identifier).pipe(
            map((response) => {
                return new House(response, regionalOfficeId);
            })
        );
    }

    fetchFavourites() {
        return super.get(new QueryParams(), 'bookmarks').pipe(
            tap((response: { houseId: string; regionalOfficeId?: string }[]) => {
                this.favourites = response;
            })
        );
    }

    fetchCompare() {
        return super.get(new QueryParams({ listType: HousesService.listTypeComparison }), 'bookmarks').pipe(
            tap((response: { houseId: string; regionalOfficeId?: string }[]) => {
                this.compare = response;
            })
        );
    }

    getComparePath(housesToCompare?: { houseId: string; regionalOfficeId?: string }[]): string {
        const houseList = housesToCompare ?? this.compare;

        const paramList = houseList.map(({ houseId, regionalOfficeId }) => (regionalOfficeId ? `${houseId}-${regionalOfficeId}` : houseId));
        return `/suche/compare/${paramList.join(',')}`;
    }

    addToFavourites(props: { houseId: string; regionalOfficeId?: string }) {
        const favouriteToCreate = {
            houseId: props.houseId,
            ...(props.regionalOfficeId ? { regionalOfficeId: props.regionalOfficeId } : {}),
        };
        return super.post(new QueryParams(favouriteToCreate), 'bookmarks').pipe(tap(() => this.favourites.push(favouriteToCreate)));
    }

    removeFromFavourites(id: string) {
        return super.delete(`bookmarks/${id}`).pipe(tap(() => (this.favourites = this.favourites.filter((val) => val.houseId !== id))));
    }

    isFavourite(id: string) {
        return !!this.favourites.find((v) => v.houseId === id);
    }

    getFavouritesCount() {
        return this.favourites.length;
    }

    getCompareCount() {
        return this.compare.length;
    }

    isCompare(id: string) {
        return !!this.compare.find((houseToCompare) => houseToCompare.houseId === id);
    }

    removeFromCompare(houseId: string) {
        return super
            .delete(`bookmarks/${houseId}?listType=${HousesService.listTypeComparison}`)
            .pipe(tap(() => (this.compare = this.compare.filter((val) => val.houseId !== houseId))));
    }

    addToCompare(added: { houseId: string; regionalOfficeid?: string }) {
        return super
            .post(
                new QueryParams({
                    houseId: added.houseId,
                    listType: HousesService.listTypeComparison,
                    ...(added.regionalOfficeid ? { regionalOfficeId: added.regionalOfficeid } : {}),
                }),
                'bookmarks'
            )
            .pipe(tap(() => this.compare.push(added)));
    }
}
