<div class="column-container">
    @if (!isCodeSent) {
        <div class="title">{{ 'verify-mobile-prompt.title' | translate }}</div>
        <div class="description">{{ 'verify-mobile-prompt.description' | translate }}</div>
        <div class="number-input">
            <mat-form-field color="accent" appearance="outline" class="mobile-code">
                <mat-label>{{ 'verify-mobile-prompt.country-code-input-label' | translate }}</mat-label>
                <mat-select [(ngModel)]="phoneCodeInputValue" [ngModelOptions]="{ standalone: true }" (ngModelChange)="numberUpdated()">
                    <mat-option
                        *ngFor="let code of phoneNumberUtil.getSupportedRegions()"
                        [value]="'+' + phoneNumberUtil.getCountryCodeForRegion(code)"
                    >
                        {{ code }} +{{ phoneNumberUtil.getCountryCodeForRegion(code) }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field color="accent" appearance="outline" class="white">
                <mat-label>{{ 'verify-mobile-prompt.mobile-number-input-label' | translate }}</mat-label>
                <input matInput required="true" [(ngModel)]="phoneNumberInputValue" (ngModelChange)="numberUpdated()" />
                <mat-error>{{ 'generic.invalid' | translate }}</mat-error>
            </mat-form-field>
        </div>

        <div class="availability">
            <div>Wann bist du am besten erreichbar?</div>
            <mat-radio-group [(ngModel)]="availabilityValue">
                <mat-radio-button [value]="1">Morgens</mat-radio-button>
                <mat-radio-button [value]="2">Mittags</mat-radio-button>
                <mat-radio-button [value]="3">Abends</mat-radio-button>
            </mat-radio-group>
        </div>

        <div class="dsgvo">
            <img src="assets/img/dsgvo-logo.png" />
            Eure Informationen sind bei uns sicher. Wir respektieren eure Privatssphäre und versprechen, eure Daten verantwortungsbewusst zu
            verwenden.
        </div>
        <div class="ssl">🔒 SSL Verschlüsselung</div>

        <button mat-flat-button color="primary" [disabled]="!isValid" (click)="saveProfileAndVerifyMobileNumber()">
            {{ 'verify-mobile-prompt.verify-button' | translate }}
        </button>
    } @else {
        <div class="title">Telefonnummer bestätigen</div>
        <div class="description">
            Gib den 6-stelligen Verfizierungscode ein den wir an {{ userToVerify?.mobile_number }} gesendet haben.
        </div>
        <div class="number-input">
            <mat-form-field color="accent" appearance="outline" class="white">
                <mat-label>SMS Code</mat-label>
                <input matInput required="true" [(ngModel)]="smsCode" maxlength="6" />
                <mat-error>{{ 'generic.invalid' | translate }}</mat-error>
            </mat-form-field>
        </div>

        <div class="code-check-buttons">
            <button mat-flat-button color="primary" [disabled]="smsCode.toString().length !== 6" (click)="checkCode()">
                SMS-Code bestätigen
            </button>
            <div class="link" (click)="resendCode()">SMS-Code erneut senden</div>
        </div>
    }
</div>
