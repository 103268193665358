import { Component, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UserService } from 'src/app/_services/user.service';
import { HistoryService } from 'src/app/_services/history.service';
import { SystemMsgService } from 'src/app/_services/system-msg.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MixpanelService } from './_services/mixpanel.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    constructor(
        private router: Router,
        private userService: UserService,
        private historyService: HistoryService,
        private renderer: Renderer2,
        private systemMsgService: SystemMsgService,
        matIconRegistry: MatIconRegistry,
        domSanitizer: DomSanitizer,
        private mixpanelService: MixpanelService
    ) {
        this.historyService.history.subscribe();
        this.listenToRouting();
        matIconRegistry.addSvgIcon('compare', domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/compare.svg'));
        matIconRegistry.addSvgIcon('compare_outlined', domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/compare_outlined.svg'));
    }

    activeRoute = '';

    private listenToRouting(): void {
        this.router.events.subscribe((value) => {
            this.activeRoute = this.router.url.substring(1).split('?')[0];
            if (this.userService.isInvalidSession() && !value.type) {
                this.userService.logout();
                this.systemMsgService.error('generic.auth-error-invalid-session').subscribe(() => this.userService.login('', 'login'));
            }
            if (value instanceof NavigationEnd) {
                this.mixpanelService.trackPageView();
            }
        });
    }
}
