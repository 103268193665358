import { Injectable } from '@angular/core';
import * as mixpanel from 'mixpanel-browser';
import { environment } from 'src/environments/environment';
import { detectDeviceType } from '../_common/app-utils';
import { House } from '../_models/leads';
import { omit, omitBy, isNil } from 'lodash-es';

export type MixpanelPropertiesType = {
    data?: {
        [key: string]: object | string | number | boolean;
    };
    uniqueId?: string;
};
export type MixpanelEventType = {
    event: string;
    uniqueId?: string;
};
export type MixpanelTrackingType = MixpanelEventType & MixpanelPropertiesType;
export type MixpanelUserInfoType = {
    data: {
        [key: string]: object | string | number | boolean;
    };
    uniqueId?: string;
    overrideValues?: boolean;
};

@Injectable({
    providedIn: 'root',
})
export class MixpanelService {
    constructor() {
        this.init();
    }

    init() {
        mixpanel.init(environment.mixpanelToken);
    }

    register() {
        mixpanel.register({
            deviceType: detectDeviceType(),
        });
    }

    /**
     * Tracks Event in Mixpanel
     *
     * @param event - the event name to be sent
     * @param data - the data to be sent alongside the event
     * @param uniqueId - User sub or requestID from identity provider.
     * If set, all events coming from current session will be assigned with the ID.
     *
     */
    track({ event, data = {}, uniqueId = undefined }: MixpanelTrackingType) {
        if (uniqueId) {
            this.identify(uniqueId);
        }
        mixpanel.track(event, { ...data });
    }

    /**
     * Identifies user for upcoming Mixpanel events.
     *
     * @param uniqueId - User sub or cidaas request ID
     */
    identify(uniqueId: string) {
        mixpanel.identify(uniqueId);
    }

    /**
     * Sets an alias for the user ID to the provided (existing) uniqueId in Mixpanel.
     * If no uniqueId provided, it will set an alias to the user's current distinct ID.
     */
    setAlias(alias: string, uniqueId?: string) {
        if (!uniqueId) {
            uniqueId = mixpanel.get_distinct_id();
        }
        mixpanel.alias(alias, uniqueId);
    }

    /**
     * Tracks page view event in Mixpanel. Used to track SPAs, which don’t naturally fire a tracking event
     *
     * @param data - the data to be sent alongside the event
     * @param uniqueId - User sub or requestID from identity provider.
     * If set, all events coming from current session will be assigned with the ID.
     *
     */
    trackPageView({ data = {}, uniqueId = undefined }: MixpanelPropertiesType = {}) {
        if (uniqueId) {
            this.identify(uniqueId);
        }
        mixpanel.default.track_pageview({ ...data });
    }

    /**
     * Starts a timer for an event in Mixpanel. Timer will run until the corresponding event is sent using track() method.
     */
    timeEvent(data: MixpanelEventType) {
        if (data.uniqueId) {
            this.identify(data.uniqueId);
        }
        mixpanel.time_event(data.event);
    }

    /**
     * Sets User data in Mixpanel
     *
     * @param data - the data to be updated for the user
     * @param uniqueId - User sub or requestID from identity provider.
     * If set, all events coming from current session will be assigned with the ID.
     * @param overrideValues - default true. If set to false, values will not be overridden if existing values are in mixpanel
     *
     */
    updateUser({ data, uniqueId = undefined, overrideValues = true }: MixpanelUserInfoType) {
        if (uniqueId) {
            this.identify(uniqueId);
        }
        if (overrideValues) {
            mixpanel.people.set(data);
        } else {
            mixpanel.people.set_once(data);
        }
    }

    getTrackingObjectFromHouse(house: House): {
        [key: string]: object | string | number | boolean;
    } {
        return {
            ...omitBy(omit(house, ['company', 'images', 'createdAt', 'updatedAt']), isNil),
            companyName: house.company?.name || '',
            companyConstructionTypes: [house.company?.constructionType || ''],
        };
    }
}
