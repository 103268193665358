import { BaseModel } from '../_common/base-model';
import { UserData } from './user';

export class JourneyStep extends BaseModel {
    id!: string;
    active!: boolean;
    stageId!: string;
    position!: number;
    title!: string;
    finishText!: string;
    cta!: {
        title: string;
        link: string;
    }[];
    description!: string;
}

export class JourneyStage extends BaseModel {
    constructor(journeyStage: JourneyStage) {
        super(journeyStage);
    }

    id!: string;
    active!: true;
    position!: number;
    name!: string;
    icon!: string;
    heroSections!: {
        image: string;
        link: string;
        text: string;
    }[];
    description!: string;
}

export class Journey extends BaseModel {
    constructor(data: { journeyStages: JourneyStage[]; journeySteps: JourneyStep[]; userData: UserData }) {
        super(data);

        this.journeyStages = this.journeyStages.map((journeyStage) => new JourneyStage(journeyStage));
        this.journeySteps = this.journeySteps.map((journeyStep) => new JourneyStep(journeyStep));
        this.userData = new UserData(this.userData);

        this.currentStageId = this.userData.journeyStage || '';
        // this.userData.journeyStage = this.userData.journeyStage || this.currentStageId;
    }

    currentStageId!: string;
    journeyStages!: JourneyStage[];
    journeySteps!: JourneyStep[];
    userData!: UserData;

    getStages() {
        return this.journeyStages.sort((a, b) => a.position - b.position);
    }

    getStepsForStage(stageId: string) {
        return this.journeySteps.filter((step) => step.stageId === stageId).sort((a, b) => a.position - b.position);
    }

    getStepsForCurrentStage() {
        return this.getStepsForStage(this.currentStageId);
    }

    getCurrentStage() {
        return this.journeyStages.find((stage) => stage.id === this.currentStageId);
    }

    isStageComplete(stageId: string) {
        const stagesIds = this.journeyStages.map((stage) => stage.id);
        return stagesIds.findIndex((v) => v === this.currentStageId) > stagesIds.findIndex((v) => v === stageId);
    }

    getNextStage() {
        const stages = this.getStages();
        const currentIndex = stages.findIndex((journeyStage) => journeyStage.id === this.currentStageId);

        if (currentIndex !== -1 && currentIndex < stages.length - 1) {
            return stages[currentIndex + 1];
        } else {
            return null;
        }
    }

    markStepAsCompleted(stepId: string) {
        const completedSteps = this.userData.journeyCompletedSteps;
        if (!completedSteps.includes(stepId)) {
            completedSteps.push(stepId);
        }
    }

    isStepCompleted(id: string) {
        return this.userData.journeyCompletedSteps.includes(id);
    }

    getCurrentStageProgress() {
        const currentStageSteps = this.getStepsForCurrentStage();
        const stepsCompleted = currentStageSteps.filter((step) => this.isStepCompleted(step.id));
        return Math.trunc((stepsCompleted.length / currentStageSteps.length) * 100) || 0;
    }

    isCurrentStageCompleted(): boolean {
        const currentStageSteps = this.getStepsForCurrentStage();
        const stepsCompleted = currentStageSteps.filter((step) => this.isStepCompleted(step.id));
        return stepsCompleted.length === currentStageSteps.length;
    }

    moveToNextStage() {
        const nextStage = this.getNextStage();
        if (nextStage) {
            this.userData.journeyStage = nextStage.id;
            this.currentStageId = nextStage.id;
        }
        return nextStage;
    }

    reset() {
        this.currentStageId = this.getStages()[0]?.id;
        this.userData = new UserData();
    }

    setStage(id: string) {
        this.userData.journeyStage = id;
        this.currentStageId = id;
    }
}
