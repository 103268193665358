import { Injectable } from '@angular/core';
import { ApiDataService } from '../_abstract/api-data.service';
import { DataService } from './data.service';
import { map } from 'rxjs/operators';
import { JourneyStep } from '../_models/journey';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class JourneyStepsService extends ApiDataService {
    constructor(dataService: DataService) {
        super(dataService, `journey-steps`);
    }

    get(): Observable<JourneyStep[]> {
        return super.get().pipe(map((journeySteps) => journeySteps.map((journeyStep: JourneyStep) => new JourneyStep(journeyStep))));
    }
}
