import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export type SystemDialogButton = { value?: any; title: string; cssClass?: string };
export type SystemDialogInputField = {
    title: string;
    required?: boolean;
    type?: 'text' | 'number';
    value?: string | number;
    pattern?: RegExp;
};

export type SystemDialogContent = {
    title: string;
    message: string;
    buttons?: SystemDialogButton[];
    cssClass?: string;
    param?: object;
    inputFields?: SystemDialogInputField[];
};
export type SubjectValue = SystemDialogContent & {
    type: 'dialog' | 'input' | 'alert';
    callbackSubject: Subject<SystemDialogButton> | Subject<SystemDialogInputField[]>;
};

@Injectable({
    providedIn: 'root',
})
export class SystemMsgService {
    private subject = new Subject<SubjectValue>();

    constructor() {}

    /**
     * Shows dialog with multiple buttons
     */
    dialog({
        title,
        message,
        buttons = [{ title: 'generic.close' }],
        cssClass = 'message',
        param,
    }: SystemDialogContent): Observable<SystemDialogButton> {
        const callbackSubject = new Subject<SystemDialogButton>();
        this.subject.next({
            type: 'dialog',
            title,
            message,
            buttons,
            cssClass,
            param,
            callbackSubject,
        });
        return callbackSubject.asObservable();
    }

    /**
     * Shows dialog with input fields
     */
    inputDialog({
        title,
        message,
        buttons = [
            { title: 'generic.cancel', cssClass: 'secondary' },
            { title: 'generic.confirm', value: 'confirm' },
        ],
        cssClass = 'message',
        param,
        inputFields: inputs,
    }: SystemDialogContent): Observable<SystemDialogInputField[]> {
        const callbackSubject = new Subject<SystemDialogInputField[]>();
        this.subject.next({
            type: 'input',
            title,
            message,
            buttons,
            cssClass,
            param,
            callbackSubject,
            inputFields: inputs,
        });
        return callbackSubject.asObservable();
    }

    alert(message: string, cssClass: string = 'message', param?: object, title?: string): Observable<any> {
        return this.dialog({ title: title || 'generic.alert', message, buttons: [{ title: 'generic.close', value: {} }], cssClass, param });
    }

    error(message: string, param?: object): Observable<any> {
        return this.dialog({ title: 'generic.error', message, buttons: [{ title: 'generic.close', value: {} }], cssClass: 'error', param });
    }

    warning(message: string, param?: object): Observable<any> {
        return this.dialog({
            title: 'generic.warning',
            message,
            buttons: [{ title: 'generic.close', value: {} }],
            cssClass: 'warning',
            param,
        });
    }

    confirm(
        message: string,
        param?: object,
        title: string = 'generic.confirm',
        confirm: string = 'generic.yes',
        cancel: string = 'generic.cancel'
    ): Observable<any> {
        return this.dialog({
            title,
            message,
            buttons: [
                { title: cancel, cssClass: 'secondary' },
                { title: confirm, value: {} },
            ],
            cssClass: 'message',
            param,
        });
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }
}
