import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        redirectTo: '/users/canvas',
        pathMatch: 'full',
    },
    {
        path: 'intro',
        loadChildren: () => import('./intro/intro.module').then((m) => m.IntroModule),
    },
    {
        path: 'questionnaire',
        title: 'Matching - euerzuhause',
        loadChildren: () => import('./questionnaire/questionnaire.module').then((m) => m.QuestionnaireModule),
    },
    {
        path: 'matches',
        title: 'Matching - euerzuhause',
        loadChildren: () => import('./matches/matches.module').then((m) => m.MatchesModule),
    },
    {
        path: 'outro',
        loadChildren: () => import('./outro/outro.module').then((m) => m.OutroModule),
    },
    {
        path: 'finanzierung',
        loadChildren: () => import('./financing/financing.module').then((m) => m.FinancingModule),
    },
    {
        path: 'tools',
        loadChildren: () => import('./tools/tools.module').then((m) => m.ToolsModule),
    },
    {
        path: 'users',
        title: 'Profil - euerzuhause',
        loadChildren: () => import('./users/users.module').then((m) => m.UsersModule),
    },
    {
        path: 'houses',
        title: 'Häuser - euerzuhause',
        loadChildren: () => import('./houses-directory/houses-directory.module').then((m) => m.HousesDirectoryModule),
    },
    {
        path: 'suche',
        title: 'Suche - euerzuhause',
        loadChildren: () => import('./houses-directory/houses-directory.module').then((m) => m.HousesDirectoryModule),
    },
    {
        path: 'companies',
        loadChildren: () => import('./companies/companies.module').then((m) => m.CompaniesModule),
    },
    {
        path: 'sandbox',
        loadChildren: () => import('./sandbox/sandbox.module').then((m) => m.SandboxModule),
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', anchorScrolling: 'enabled' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
