import { BaseModel } from '../_common/base-model';

export class User extends BaseModel {
    'email'!: string;
    'locale'!: string;
    'name'!: string;
    'given_name'!: string;
    'family_name'!: string;
    'mobile_number'!: string;
    'nickname'!: string;
    'provider'!: string;
    'roles'!: Array<string>;
    'scopes'!: Array<string>;
    'sub'!: string;
}

export class UserData extends BaseModel {
    constructor(userData?: unknown) {
        super(userData);
        this.journeyCompletedSteps = this.journeyCompletedSteps || [];
        this.journeyStage = this.journeyStage || '';
    }

    id!: string;
    userSub!: string;
    email!: string;
    journeyStage!: string;
    journeyCompletedSteps!: string[];
    createdAt!: string;
}
