<div id="app-header">
    <div class="top-menu" [class]="path">
        <div class="back-button-container">
            <div (click)="previousQuestion()" *ngIf="hasPreviousQuestion()" class="back-button">
                <mat-icon>arrow_back</mat-icon>
            </div>
        </div>

        <div class="history-back-button">
            <div (click)="historyBack()" class="back-button">
                <mat-icon>arrow_back</mat-icon>
            </div>
        </div>

        <div class="logo" (click)="logoClick()" gaEvent="logo-click"></div>

        <div class="links-menu" [class.toggle-on]="mobileMenuVisible" [class]="path">
            <app-links-menu [user]="this.user" (login)="login()"></app-links-menu>
        </div>

        <div class="menu-container">
            <div class="menu">
                <!--                <mat-icon-->
                <!--                    class="menu-favourites"-->
                <!--                    (click)="navigateToHouseDirectory()"-->
                <!--                    matBadge="{{ getFavouritesCount() }}"-->
                <!--                    matBadgeSize="small"-->
                <!--                    [matBadgeHidden]="isFavouriteBadgeHidden()"-->
                <!--                    aria-hidden="false"-->
                <!--                >-->
                <!--                    favorite_outline-->
                <!--                </mat-icon>-->
                <!--                <mat-icon-->
                <!--                    class="menu-compare"-->
                <!--                    (click)="navigateToCompare()"-->
                <!--                    svgIcon="compare_outlined"-->
                <!--                    matBadge="{{ getCompareCount() }}"-->
                <!--                    matBadgeSize="small"-->
                <!--                    [matBadgeHidden]="isCompareBadgeHidden()"-->
                <!--                    aria-hidden="false"-->
                <!--                ></mat-icon>-->

                <mat-icon (click)="showMenu()" class="show-on-mobile" aria-hidden="false">
                    {{ mobileMenuVisible ? 'close' : 'menu' }}
                </mat-icon>
                <div class="user-icon hide-on-mobile" *ngIf="this.user" [routerLink]="['/users/canvas']">
                    {{ userFirstChar }}
                </div>
                <div class="button login-button hide-on-mobile" *ngIf="!this.user" (click)="login()">
                    {{ 'shared.header.login' | translate }}
                </div>
            </div>
        </div>
    </div>
</div>
