import { Component, EventEmitter, Input, Output } from '@angular/core';
import { User } from 'src/app/_models/user';

interface LinksMenuInterface {
    name: string;
    url: string;
    links: { title: string; url: string; local?: boolean }[];
}

@Component({
    selector: 'app-links-menu',
    templateUrl: './links-menu.component.html',
    styleUrl: './links-menu.component.scss',
})
export class LinksMenuComponent {
    @Output() login = new EventEmitter<void>();
    @Input() user: User | undefined;

    planenLinks: LinksMenuInterface[][] = [
        [
            {
                name: 'Bauweisen',
                url: 'https://www.euerzuhause.de/planen/bauweisen',
                links: [
                    {
                        title: 'Fertighaus',
                        url: 'https://www.euerzuhause.de/planen/bauweisen/fertighaus',
                    },
                    {
                        title: 'Massivhaus',
                        url: 'https://www.euerzuhause.de/planen/bauweisen/massivhaus',
                    },
                    {
                        title: 'Ausbauhaus',
                        url: 'https://www.euerzuhause.de/planen/bauweisen/ausbauhaus',
                    },
                    {
                        title: 'Energiesparhaus',
                        url: 'https://www.euerzuhause.de/planen/bauweisen/energiesparhaus',
                    },
                ],
            },
            {
                name: 'Bauplanung',
                url: 'https://www.euerzuhause.de/planen/bauplanung',
                links: [
                    {
                        title: 'Planungsphase',
                        url: 'http://www.euerzuhause.de/planen/bauplanung/planungsphase',
                    },
                    {
                        title: 'Baupartner wählen',
                        url: 'http://www.euerzuhause.de/planen/bauplanung/baupartner-waehlen',
                    },
                    {
                        title: 'Bebauungsplan',
                        url: 'http://www.euerzuhause.de/planen/bauplanung/bebauungsplan',
                    },
                ],
            },
            {
                name: 'Baurecht',
                url: 'https://www.euerzuhause.de/planen/bauweisen',
                links: [
                    {
                        title: 'Baugenehmigung',
                        url: 'http://www.euerzuhause.de/planen/baurecht/baugenehmigung',
                    },
                    {
                        title: 'Bauabnahme',
                        url: 'http://www.euerzuhause.de/planen/baurecht/bauabnahme',
                    },
                ],
            },
        ],
        [
            {
                name: 'Grundstück',
                url: 'https://www.euerzuhause.de/planen/grundstueck',
                links: [
                    {
                        title: 'Grundstücksuche',
                        url: 'http://www.euerzuhause.de/planen/grundstuek/grundstuecksuche',
                    },
                    {
                        title: 'Grundstückkauf',
                        url: 'http://www.euerzuhause.de/planen/grundstuek/grundstueckkauf',
                    },
                    {
                        title: 'Tipps & Tricks',
                        url: 'http://www.euerzuhause.de/planen/grundstuek/grundstueck-tipps-tricks',
                    },
                ],
            },
            {
                name: 'Grundriss',
                url: 'https://www.euerzuhause.de/planen/grundriss',
                links: [
                    {
                        title: 'Grundrissplanung',
                        url: 'http://www.euerzuhause.de/planen/grundriss/grundrissplanung',
                    },
                    {
                        title: 'Grundriss lesen',
                        url: 'http://www.euerzuhause.de/planen/grundriss/grundriss-lesen',
                    },
                    {
                        title: 'Tipps & Tricks',
                        url: 'http://www.euerzuhause.de/planen/grundriss/tipps-tricks',
                    },
                ],
            },
            {
                name: 'Baukosten',
                url: 'https://www.euerzuhause.de/planen/baukosten',
                links: [
                    {
                        title: 'Zusammensetzung',
                        url: 'http://www.euerzuhause.de/planen/baukosten/zusammensetzung',
                    },
                    {
                        title: 'Tipps zur Optimierung',
                        url: 'http://www.euerzuhause.de/planen/baukosten/optimierung',
                    },
                ],
            },
        ],
        [
            {
                name: 'Finanzierung',
                url: 'https://www.euerzuhause.de/planen/finanzierung',
                links: [
                    {
                        title: 'Baufinanzierung',
                        url: 'http://www.euerzuhause.de/planen/finanzierung/baufinanzierung',
                    },
                    {
                        title: 'Fördeprogramme 2024',
                        url: 'http://www.euerzuhause.de/planen/finanzierung/foerderprogramme-2024',
                    },
                    {
                        title: 'Förderprogramme',
                        url: 'http://www.euerzuhause.de/planen/finanzierung/foerderprogramme',
                    },
                    {
                        title: 'Tipps & Tricks',
                        url: 'http://www.euerzuhause.de/planen/finanzierung/tipps-und-tricks',
                    },
                ],
            },
            {
                name: 'Smart-Home',
                url: 'https://www.euerzuhause.de/planen/smart-home',
                links: [
                    {
                        title: 'Überblick',
                        url: 'http://www.euerzuhause.de/planen/smart-home/ueberblick',
                    },
                    {
                        title: 'Smart Home im Fertighaus',
                        url: 'http://www.euerzuhause.de/planen/smart-home/smart-home-fertighaus',
                    },
                    {
                        title: 'Experteninterview',
                        url: 'http://www.euerzuhause.de/planen/smart-home/experteninterview',
                    },
                ],
            },
            {
                name: 'Versicherungen',
                url: 'https://www.euerzuhause.de/planen/versicherungen',
                links: [
                    {
                        title: 'Überblick',
                        url: 'http://www.euerzuhause.de/planen/versicherungen/ueberblick',
                    },
                    {
                        title: 'Kosten',
                        url: 'http://www.euerzuhause.de/planen/versicherungen/kosten',
                    },
                    {
                        title: 'Tipps & Tricks',
                        url: 'http://www.euerzuhause.de/planen/versicherungen/tipps-tricks',
                    },
                ],
            },
        ],
    ];

    bauenLinks: LinksMenuInterface[][] = [
        [
            {
                name: 'Bauphasen',
                url: 'https://www.euerzuhause.de/bauen/bauphasen',
                links: [
                    {
                        title: 'Ablauf',
                        url: 'http://www.euerzuhause.de/bauen/bauphasen/ablauf',
                    },
                    {
                        title: 'Gewerke',
                        url: 'http://www.euerzuhause.de/bauen/bauphasen/gewerke',
                    },
                    {
                        title: 'Bauabnahme',
                        url: 'http://www.euerzuhause.de/bauen/bauphasen/bauabnahme',
                    },
                ],
            },
            {
                name: 'Dach',
                url: 'https://www.euerzuhause.de/bauen/dach',
                links: [
                    {
                        title: 'Formen',
                        url: 'http://www.euerzuhause.de/bauen/dach/dachformen',
                    },
                    {
                        title: 'Dämmungen',
                        url: 'http://www.euerzuhause.de/bauen/dach/daemmung',
                    },
                ],
            },
            {
                name: 'Dämmung',
                url: 'https://www.euerzuhause.de/bauen/daemmung',
                links: [
                    {
                        title: 'Baugenehmigung',
                        url: 'http://www.euerzuhause.de/bauen/daemmung/daemmarten',
                    },
                    {
                        title: 'Bauabnahme',
                        url: 'http://www.euerzuhause.de/bauen/daemmung/trends',
                    },
                ],
            },
        ],
        [
            {
                name: 'Heiztechnik',
                url: 'https://www.euerzuhause.de/bauen/heiztechnik',
                links: [
                    {
                        title: 'Überblick',
                        url: 'http://www.euerzuhause.de/bauen/heiztechnik/ueberblick',
                    },
                    {
                        title: 'Wärmepumpe',
                        url: 'http://www.euerzuhause.de/content/bauen-heiztechnik-waermepumpe',
                    },
                ],
            },
            {
                name: 'Energie',
                url: 'https://www.euerzuhause.de/bauen/energie',
                links: [
                    {
                        title: 'Energiestandards',
                        url: 'http://www.euerzuhause.de/bauen/energie/energiestandards',
                    },
                ],
            },
            {
                name: 'Fenster & Türen',
                url: 'https://www.euerzuhause.de/bauen/fenster-tueren',
                links: [
                    {
                        title: 'Fenster',
                        url: 'http://www.euerzuhause.de/bauen/fenster-tueren/fensterarten',
                    },
                    {
                        title: 'Türen',
                        url: 'http://www.eurzuhause.de/bauen/fenster-tueren/tuerarten',
                    },
                ],
            },
        ],
        [
            {
                name: 'Innenausbau',
                url: 'http://www.euerzuhause.de/bauen/innenausbau',
                links: [
                    {
                        title: 'Barrierefreies Bauen',
                        url: 'http://www.euerzuhause.de/bauen/innenausbau/barrierefreies-bauen',
                    },
                ],
            },
            {
                name: 'Keller',
                url: 'https://www.euerzuhause.de/bauen/keller',
                links: [
                    {
                        title: 'Arten',
                        url: 'http://www.euerzuhause.de/bauen/keller/arten',
                    },
                    {
                        title: 'Kosten',
                        url: 'http://www.euerzuhause.de/bauen/keller/kosten',
                    },
                ],
            },
            {
                name: 'Mauerwerk',
                url: 'https://www.euerzuhause.de/bauen/mauerwerk',
                links: [
                    {
                        title: 'Massivbau',
                        url: 'http://www.euerzuhause.de/bauen/mauerwerk/massivbau',
                    },
                    {
                        title: 'Fertighausbau',
                        url: 'http://www.euerzuhause.de/bauen/mauerwerk/fertighaus',
                    },
                ],
            },
        ],
        [
            {
                name: 'Außenanlagen',
                url: 'http://www.euerzuhause.de/bauen/aussenanlagen',
                links: [
                    {
                        title: 'Garten & Terrasse',
                        url: 'http://www.euerzuhause.de/bauen/aussenanlagen/garten-terrasse',
                    },
                    {
                        title: 'Garage - Auffahrt - Gehweg',
                        url: 'http://www.euerzuhause.de/bauen/aussenanlagen/garage-einfahrt-gehwege',
                    },
                ],
            },
        ],
    ];

    servicesLinks: LinksMenuInterface[][] = [
        [
            {
                name: 'Services',
                url: '',
                links: [
                    {
                        title: 'Hausbauguide',
                        url: '/users/canvas',
                        local: true,
                    },
                    {
                        title: 'Vergleichsangebot',
                        url: 'https://www.euerzuhause.de/vergleichsangebot',
                    },
                ],
            },
        ],
    ];
}
