import { Questionnaire, QuestionnaireAnswers } from 'src/app/_models/questionnaire';
import { BaseModel } from 'src/app/_common/base-model';
import { merge } from 'lodash-es';
import { House } from './leads';
import { PaginationInterface } from '../_common/interface/pagination.interface';

export class MatchesSummaryPagination extends BaseModel implements PaginationInterface {
    constructor(matchesSummaryPagination: MatchesSummaryPagination) {
        super(matchesSummaryPagination);
        this.docs = this.docs.map((matchesSummary) => new MatchesSummary(matchesSummary));
    }

    docs!: MatchesSummary[];
}

export interface PreSignedUrl {
    path: string;
    presignedUrl: string;
}

export interface SelectedCompany {
    companyId: string;
    regionalOfficeId: string | undefined;
}

export class MatchesSummary extends BaseModel {
    constructor(matchesSummary: MatchesSummary) {
        super(matchesSummary);
        this.houses = this.houses?.map((house) => new House(house)) || [];
        merge(this.questionnaire, this.answers);
        this.questionnaire = new Questionnaire(this.questionnaire);
        this.answers = new QuestionnaireAnswers(this.answers);
    }

    id!: string;
    questionnaire!: Questionnaire;
    answers!: QuestionnaireAnswers;
    status!: 'IN_PROGRESS' | 'COMPLETED';
    userType?: UserType;
    features?: { skipPreResultsPage?: boolean };
    algorithm!: string;
    houses!: House[];
    selectedCompanies!: SelectedCompany[];
}

export enum UserType {
    gambler = 'GAMBLER',
    dreamerNoFin = 'DREAMER_NO_FIN',
    dreamerNoPlot = 'DREAMER_NO_PLOT',
    earlyDreamer = 'EARLY_DREAMER',
    earlyBuilder = 'EARLY_BUILDER',
    builder = 'BUILDER',
}

export class Image extends BaseModel {
    type!: string;
    url!: string;

    getPath(): string {
        return new URL(this.url).pathname;
    }

    getFileType(): string {
        return this.url.slice(-3).toLowerCase() === 'pdf' ? 'typePdf' : 'typeImage';
    }
}

export class Attribute extends BaseModel {
    operationType!: OperationType;
    attributeName!: HouseAttributeName;
    attributeValue!: number | boolean;
}

export enum OperationType {
    min = 'MIN',
    max = 'MAX',
    equal = 'EQUAL',
}

export enum HouseAttributeName {
    price = 'price',
    totalLivingArea = 'totalLivingArea',
    totalRooms = 'totalRooms',
    totalFlexrooms = 'totalFlexrooms',
    numberOfWCs = 'numberOfWCs',
    hasEnSuite = 'hasEnSuite',
    hasUtilityRoom = 'hasUtilityRoom',
    hasOfficeRoom = 'hasOfficeRoom',
    hasBalcony = 'hasBalcony',
    hasPatio = 'hasPatio',
    numberOfBathrooms = 'numberOfBathrooms',
    hasStoreRoom = 'hasStoreRoom',
    hasOpenKitchen = 'hasOpenKitchen',
    hasDressingRoom = 'hasDressingRoom',
    hasLaundryRoom = 'hasLaundryRoom',
}
