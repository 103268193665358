import { DeserializeInterface } from './interface/deserialize.interface';

export class BaseModel implements DeserializeInterface {
    constructor(data?: unknown) {
        this.deserialize(data);
    }

    deserialize(input: unknown): this {
        Object.assign(this, input);
        return this;
    }
}
