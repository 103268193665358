<form *ngIf="currentMessage" class="modal" #form="ngForm">
    <div class="modal-body {{ currentMessage.cssClass }}">
        <div class="title" *ngIf="currentMessage.title">
            <mat-icon class="type-icon warning">warning_amber</mat-icon>
            <mat-icon class="type-icon error">error_outlined</mat-icon>
            <span>{{ currentMessage.title | translate: currentMessage.param }}</span>
        </div>
        <div class="message" [innerHtml]="currentMessage.message | translate: currentMessage.param"></div>
        <div class="inputs">
            <ng-container *ngFor="let input of currentMessage.inputFields">
                <mat-form-field color="accent" appearance="outline" class="white">
                    <mat-label>{{ input.title | translate }}</mat-label>
                    <input
                        matInput
                        [required]="input.required || true"
                        [type]="input.type || 'text'"
                        [(ngModel)]="input.value"
                        [pattern]="input.pattern || ''"
                        [name]="input.title"
                    />
                    <mat-error>{{ 'generic.invalid' | translate }}</mat-error>
                </mat-form-field>
            </ng-container>
        </div>
        <div class="buttons">
            <ng-container *ngFor="let button of currentMessage.buttons">
                <button color="accent" *ngIf="button?.cssClass === 'secondary'" (click)="callbackAndClose(button)" mat-stroked-button>
                    {{ button.title | translate }}
                </button>
                <button
                    color="primary"
                    [disabled]="form.invalid"
                    *ngIf="button?.cssClass !== 'secondary'"
                    (click)="callbackAndClose(button)"
                    mat-flat-button
                >
                    {{ button.title | translate }}
                </button>
            </ng-container>
        </div>
    </div>
</form>
<div *ngIf="currentMessage" class="modal-background"></div>
