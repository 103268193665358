<app-modal *ngIf="getLoginRegisterPromptVisible()" id="social-login-prompt">
    <div class="content">
        <div class="text">
            <div class="header">
                <mat-icon class="link" (click)="hide()">close</mat-icon>
            </div>
            <div class="description top">
                {{ 'shared.social-login-prompt.title' | translate }}
            </div>
            <div class="description top">
                {{ 'shared.social-login-prompt.description.0' | translate }}
            </div>
            <div class="description top">
                {{ 'shared.social-login-prompt.description.1' | translate }}
            </div>
            <div class="description top">
                {{ 'shared.social-login-prompt.description.2' | translate }}
            </div>
            <div class="actions">
                <button mat-stroked-button class="button-login social" (click)="googleLogin()">
                    {{ 'shared.social-login-prompt.google' | translate }}
                </button>
                <button mat-stroked-button class="button-login social" (click)="appleLogin()">
                    {{ 'shared.social-login-prompt.apple' | translate }}
                </button>
                <button mat-stroked-button class="button-login" (click)="credentialsLogin()">
                    {{ 'shared.social-login-prompt.credentials' | translate }}
                </button>
            </div>
        </div>
    </div>
</app-modal>
