import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { SystemNotificationService } from '../../_services/system-notification.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-system-notification',
    templateUrl: './system-notification.component.html',
    styleUrls: ['./system-notification.component.scss'],
})
export class SystemNotificationComponent implements OnInit {
    @ViewChild('templateRef') public templateRef!: TemplateRef<unknown>;
    displayMsg:
        | {
              message: string;
              param: unknown;
          }
        | undefined;
    messages: {
        message: string;
        param: unknown;
    }[] = [];
    private isOpen: boolean | undefined;

    constructor(
        private systemNotificationService: SystemNotificationService,
        private snackBar: MatSnackBar
    ) {}

    ngOnInit(): void {
        this.systemNotificationService.getMessage().subscribe((message) => {
            this.messages.push(message);
            this.show();
        });
    }

    show(): void {
        if (!this.isOpen) {
            this.isOpen = true;
            this.displayMsg = this.messages.shift();
            this.snackBar
                .openFromTemplate(this.templateRef, {
                    duration: 2000,
                    horizontalPosition: 'right',
                    verticalPosition: 'bottom',
                    panelClass: 'system-notification',
                })
                .afterDismissed()
                .subscribe(() => {
                    this.isOpen = false;
                    if (this.messages.length) {
                        this.show();
                    }
                });
        }
    }
}
