import { BaseModel } from '../_common/base-model';

export class UserInfoDetails extends BaseModel {
    'userAccount': {
        userIds: {
            key: string;
            value: unknown;
        }[];
        uniqueIndexes: [];
        className: string;
        sub: string;
        user_status_reason: string;
        userStatus: string;
        customFields: {
            availability_euerzuhause: string;
            consent_pp_tc_euerzuhause: boolean;
        };
        lastLoggedInTime: string;
        lastUsedIdentity: string;
        createdTime: string;
        updatedTime: string;
        id: string;
    };
    'identity': {
        sub: string;
        originalProviderUserId: [];
        given_name: string;
        family_name: string;
        email: string;
        email_verified: boolean;
        mobile_number: string;
        mobile_number_verified: boolean;
        providerBusinessIds: [];
        provider: string;
        className: string;
        createdTime: string;
        updatedTime: string;
        id: string;
    };
    'customFields': {
        availability_euerzuhause: {
            fieldKey: 'availability_euerzuhause';
            value: '1' | '2' | '3';
            key: 'availability_euerzuhause';
            dataType: 'RADIO';
            internal: false;
            readOnly: false;
        };
        consent_pp_tc_euerzuhause: {
            fieldKey: 'consent_pp_tc_euerzuhause';
            value: boolean;
            key: 'consent_pp_tc_euerzuhause';
            dataType: 'CONSENT';
            internal: false;
            readOnly: false;
        };
    };
    'roles': string[];
    'groups': [];
}
