import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from 'src/app/_shared/header/header.component';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from 'src/app/_core/_core.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { FooterComponent } from './footer/footer.component';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterLink, RouterLinkActive } from 'node_modules/@angular/router';
import { LoginRegisterPromptComponent } from './login-register-prompt/login-register-prompt.component';
import { VerifyMobilePromptComponent } from './verify-mobile-prompt/verify-mobile-prompt.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatBadgeModule } from '@angular/material/badge';
import { MatchesPreviewRegisterPromptComponent } from './matches-preview-register-prompt/matches-preview-register-prompt.component';
import { MatRadioModule } from '@angular/material/radio';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';
import { LinksMenuComponent } from './links-menu/links-menu.component';
import { ZipCodePromptComponent } from './zip-code-prompt/zip-code-prompt.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SecondaryNavigationComponent } from './secondary-navigation/secondary-navigation.component';
import { SocialLoginPromptComponent } from './social-login-prompt/social-login-prompt.component';
import { UnclosableSocialLoginPromptComponent } from './unclosable-social-login-prompt/unclosable-social-login-prompt.component';

@NgModule({
    declarations: [
        HeaderComponent,
        FooterComponent,
        LoginRegisterPromptComponent,
        SocialLoginPromptComponent,
        VerifyMobilePromptComponent,
        MatchesPreviewRegisterPromptComponent,
        LinksMenuComponent,
        ZipCodePromptComponent,
        SecondaryNavigationComponent,
        UnclosableSocialLoginPromptComponent,
    ],
    imports: [
        CommonModule,
        TranslateModule,
        CoreModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        RouterLink,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        FormsModule,
        MatSelectModule,
        MatBadgeModule,
        MatRadioModule,
        NgxGoogleAnalyticsModule,
        NgxGpAutocompleteModule,
        MatProgressSpinnerModule,
        RouterLinkActive,
    ],
    exports: [
        HeaderComponent,
        FooterComponent,
        LoginRegisterPromptComponent,
        SocialLoginPromptComponent,
        VerifyMobilePromptComponent,
        MatchesPreviewRegisterPromptComponent,
        ZipCodePromptComponent,
        SecondaryNavigationComponent,
        UnclosableSocialLoginPromptComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
