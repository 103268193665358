import { Injectable } from '@angular/core';
import { DataService } from 'src/app/_services/data.service';
import { Observable, tap } from 'rxjs';
import { Questionnaire } from 'src/app/_models/questionnaire';
import { map } from 'rxjs/operators';
import { EventEmitter } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class QuestionnaireService {
    constructor(private dataService: DataService) {}

    previousQuestionEvent = new EventEmitter();

    questionnaire: Questionnaire | undefined;

    getCachedQuestionnaire() {
        return this.questionnaire;
    }

    get(questionnaireName = 'questionnaire'): Observable<Questionnaire> {
        return this.dataService
            .get(`./assets/questionnaire/${questionnaireName}.json`)
            .pipe(map((response) => new Questionnaire(response)))
            .pipe(tap((response) => (this.questionnaire = response)));
    }
}
