import { Component } from '@angular/core';
import { UserService } from 'src/app/_services/user.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
    selector: 'app-login-register-prompt',
    templateUrl: './login-register-prompt.component.html',
    styleUrls: ['./login-register-prompt.component.scss'],
})
export class LoginRegisterPromptComponent {
    constructor(
        private userService: UserService,
        private googleAnalyticsService: GoogleAnalyticsService
    ) {
        this.googleAnalyticsService.event('signup_started', 'users', 'house_directory');
    }

    getLoginRegisterPromptVisible() {
        return this.userService.getLoginRegisterPromptVisible().visibility.closablePromptVisible;
    }

    hide() {
        this.userService.setLoginRegisterPromptVisible({ closablePromptVisible: false });
    }

    register() {
        this.userService.login('', 'email');
    }

    login() {
        this.userService.login('', 'login');
    }
}
