import { Injectable } from '@angular/core';
import { BrowserPlatformLocation } from 'node_modules/@angular/common';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class UtilsService {
    constructor(
        private browserPlatformLocation: BrowserPlatformLocation,
        private googleAnalyticsService: GoogleAnalyticsService
    ) {}

    feedbackPopup(config: {
        template: string;
        triggerIdle?: string;
        triggerBack?: string;
        triggerTabChange?: string;
        triggerScrollBottom?: string;
    }) {
        if (window.hasOwnProperty('ezhSetProps')) {
            (window as any).ezhSetProps({
                dataApiUrl: `${environment.dataEndpoint}/feedback`,
                dataTemplate: config.template,
                dataTriggerIdle: config.triggerIdle,
                dataTriggerBack: config.triggerBack,
                dataTriggerScroll: '',
                dataTriggerScrollBottom: config.triggerScrollBottom,
                dataTriggerTabChange: config.triggerTabChange,
                callback: (eventName: string, template: string) => {
                    this.googleAnalyticsService.event(`feedback_${eventName}`, 'feedback', template);
                },
            });
        }
    }

    getBaseUrlWithPath() {
        const baseHref = this.browserPlatformLocation.getBaseHrefFromDOM().replace(/(\/*$)/g, '');
        return `${window.location.origin}${baseHref}`;
    }
}
