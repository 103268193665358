import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SubjectValue, SystemDialogButton, SystemDialogInputField, SystemMsgService } from '../../_services/system-msg.service';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-system-msg',
    templateUrl: './system-msg.component.html',
    styleUrls: ['./system-msg.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SystemMsgComponent implements OnInit {
    messages: any = [];
    currentMessage!: SubjectValue;

    constructor(private systemMsgService: SystemMsgService) {}

    ngOnInit(): void {
        this.systemMsgService.getMessage().subscribe((message) => {
            this.messages.push(message);
            if (!this.currentMessage) {
                this.close();
            }
        });
    }

    open(): void {
        // this.modal.open();
    }

    callbackAndClose(button: { value?: any; title: string }): void {
        if (button?.value) {
            if (this.currentMessage.type === 'input' && this.currentMessage.inputFields && button.value === 'confirm') {
                (this.currentMessage.callbackSubject as Subject<SystemDialogInputField[]>).next(this.currentMessage.inputFields);
            } else if (this.currentMessage.type === 'dialog') {
                (this.currentMessage.callbackSubject as Subject<SystemDialogButton>).next(button);
            }
        }
        this.currentMessage.callbackSubject.complete();
        this.close();
    }

    close(): void {
        this.currentMessage = this.messages.pop();
    }
}
