import { Injectable } from '@angular/core';
import { ApiDataService } from '../_abstract/api-data.service';
import { DataService } from './data.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserData } from '../_models/user';

@Injectable({
    providedIn: 'root',
})
export class UserDataService extends ApiDataService {
    constructor(dataService: DataService) {
        super(dataService, `user`);
    }

    get(): Observable<UserData> {
        return super.get().pipe(map((userData: UserData) => new UserData(userData)));
    }
}
