import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';

@Component({
    selector: 'app-zip-code-prompt',
    templateUrl: './zip-code-prompt.component.html',
    styleUrl: './zip-code-prompt.component.scss',
})
export class ZipCodePromptComponent implements OnInit {
    @Input() zip = {
        postalCode: '',
        postalCodeCity: '',
    };

    @Output() zipSelected = new EventEmitter<{ postalCode: string; postalCodeCity: string }>();
    @Output() cancel = new EventEmitter<void>();

    inputFieldLoading = false;

    constructor(private googleMapsLoader: Loader) {}

    ngOnInit() {
        if (this.zip.postalCode && !this.zip.postalCodeCity) {
            this.inputFieldLoading = true;
            const postalCode = this.zip.postalCode;
            this.zip.postalCode = '';
            this.googleMapsLoader
                .importLibrary('places')
                .then(() => {
                    this.lookupCityForInitialPostalCode(postalCode);
                })
                .catch((error) => {
                    console.error('Google Maps API loading error: ', error);
                    this.inputFieldLoading = false;
                });
        }
    }

    lookupCityForInitialPostalCode(postalCode: string) {
        const service = new google.maps.places.PlacesService(document.createElement('div'));
        const request = {
            query: `DE-${postalCode}`,
            fields: ['place_id'],
        };

        service.findPlaceFromQuery({ ...request }, (results, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK && results?.length === 1 && results[0].place_id) {
                const placeId = results[0].place_id;
                service.getDetails({ placeId, fields: ['ALL'] }, (detailsResult, status) => {
                    if (status === google.maps.places.PlacesServiceStatus.OK && detailsResult?.address_components) {
                        const city = detailsResult.address_components.find((component) => component.types?.includes('locality'));
                        if (city) {
                            this.inputFieldLoading = false;
                            this.zip.postalCodeCity = city.long_name;
                            this.zip.postalCode = postalCode;
                        }
                    }
                    if (!this.zip.postalCodeCity) {
                        this.inputFieldLoading = false;
                        this.zip.postalCode = '';
                    }
                });
            } else {
                this.inputFieldLoading = false;
                this.zip.postalCode = '';
            }
        });
    }

    codeChange(placeResult: google.maps.places.PlaceResult) {
        this.zip.postalCode =
            placeResult.address_components?.find((addressComponent) => addressComponent.types.includes('postal_code'))?.long_name || '';
        this.zip.postalCodeCity =
            placeResult.address_components?.find((addressComponent) => addressComponent.types.includes('locality'))?.long_name || '';
    }

    zipCallback() {
        if (this.zip.postalCode && this.zip.postalCodeCity) {
            this.zipSelected.emit(this.zip);
        }
    }

    cancelCallback() {
        this.cancel.emit();
    }
}
