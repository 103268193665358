import { Component, Input, OnInit } from '@angular/core';
import { UserService } from 'src/app/_services/user.service';
import { User } from 'src/app/_models/user';
import { SystemMsgService } from 'src/app/_services/system-msg.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { HousesService } from 'src/app/_services/houses.service';
import { QuestionnaireService } from 'src/app/_services/questionnaire.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    user: User | undefined;
    userFirstChar: string | undefined;
    favouriteBadgeHidden: boolean;
    compareBadgeHidden: boolean;
    favouriteCount: number | undefined;
    compareCount: number | undefined;
    mobileMenuVisible = false;

    @Input() path: string | undefined;

    constructor(
        private userService: UserService,
        private systemMsgService: SystemMsgService,
        private router: Router,
        private housesService: HousesService,
        private questionnaireService: QuestionnaireService
    ) {
        this.user = userService.getUser();
        this.favouriteBadgeHidden = true;
        this.compareBadgeHidden = true;
        this.userService.oauthService.events.subscribe(() => {
            this.getUserDetails();
        });
    }

    ngOnInit(): void {
        this.getUserDetails();
        this.router.events.subscribe(() => (this.mobileMenuVisible = false));
    }

    hasPreviousQuestion() {
        return !!this.questionnaireService.getCachedQuestionnaire()?.hasPreviousQuestion();
    }

    getFavouritesCount() {
        this.favouriteCount = this.housesService.getFavouritesCount();
        this.favouriteBadgeHidden = this.favouriteCount <= 0;
        return this.favouriteCount;
    }

    getUserDetails() {
        this.user = this.userService.getUser();
        this.userFirstChar = this.user?.given_name?.charAt(0);
    }

    login() {
        this.userService.login('/users/canvas', 'login');
    }

    logout() {
        this.userService.logout(() => this.router.navigate(['/users/logout-success']));
    }

    logoClick() {
        if (this.path === 'companies/compare' || this.path?.includes('finanzierung')) {
            this.systemMsgService
                .dialog({
                    title: 'Möchten Sie die Seite verlassen?',
                    message: 'Möchten Sie sich anmelden, um Ihre Fortschritte zu speichern?',
                    buttons: [
                        { value: 'register', title: 'Registrieren' },
                        { value: 'leave', title: 'Startseite', cssClass: 'secondary' },
                    ],
                })
                .subscribe((button) => {
                    if (button.value === 'leave') {
                        window.location.href = environment.landingPage;
                    } else {
                        this.userService.setLoginRegisterPromptVisible({ closablePromptVisible: true });
                    }
                });
            return;
        }

        if (this.path === 'questionnaire' || this.path === 'companies/compare') {
            this.systemMsgService.confirm('generic.leave-confirmation').subscribe(() => (window.location.href = environment.landingPage));
        } else {
            window.location.href = environment.landingPage;
        }
    }

    getCompareCount() {
        this.compareCount = this.housesService.getCompareCount();
        this.compareBadgeHidden = this.compareCount <= 0;
        return this.compareCount;
    }

    navigateToHouseDirectory() {
        if (!this.user) {
            this.userService.setLoginRegisterPromptVisible({ closablePromptVisible: true });
        } else {
            this.router.navigate(['/suche'], { queryParams: { isBookmarked: true, limit: 11 } }).then();
        }
    }

    navigateToCompare() {
        if (!this.user) {
            this.userService.setLoginRegisterPromptVisible({ closablePromptVisible: true });
        } else {
            this.router.navigate([this.housesService.getComparePath()]).then();
        }
    }

    isFavouriteBadgeHidden() {
        if (!this.user) {
            return true;
        } else {
            return this.favouriteBadgeHidden;
        }
    }

    isCompareBadgeHidden() {
        if (!this.user) {
            return true;
        } else {
            return this.compareBadgeHidden;
        }
    }

    previousQuestion() {
        if (this.questionnaireService.getCachedQuestionnaire()?.hasPreviousQuestion()) {
            this.questionnaireService.previousQuestionEvent.emit();
        }
    }

    showMenu() {
        this.mobileMenuVisible = !this.mobileMenuVisible;
    }

    historyBack() {
        history.back();
    }
}
