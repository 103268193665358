import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class LocalStorageService {
    constructor() {}

    static get(key: string): object {
        return JSON.parse((localStorage.getItem(key) !== null && localStorage.getItem(key)) || '{}');
    }

    static set(key: string, value: any): void {
        return localStorage.setItem(key, JSON.stringify(value));
    }

    static removeItem(key: string): void {
        return localStorage.removeItem(key);
    }

    static clear(): void {
        return localStorage.clear();
    }
}
