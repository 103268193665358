export function getMongoEmailPattern(): string {
    return (
        "^([-!#$%&'*+/=?^_`{}|~0-9a-zA-Z]+(\\.[-!#$%&'*+/=?^_`{}|~0-9a-zA-Z]+)*|^\\\"([\\001-\\010\\013\\014\\016-" +
        '\\037!#-\\[\\]-\\177]|\\\\[\\001-011\\013\\014\\016-\\177])*\\")@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\\.)+[a-zA-Z]{2,6}\\.?$'
    );
}

export function isTesting(): boolean {
    return window.hasOwnProperty('Cypress');
}

export function extractTextInCurlyBraces(inputString: string) {
    const regex = /\{([^}]+)}/; // Regular expression to match text inside curly braces
    const match = regex.exec(inputString); // Executing the regular expression on the input string
    if (match && match[1]) {
        return match[1]; // Returning the text inside the curly braces
    } else {
        return null; // Returning null if no match is found
    }
}

export function replaceTextInCurlyBraces(inputString: string, replacement: string) {
    return inputString.replace(/\{([^}]+)}/g, replacement);
}

export const detectDeviceType = () => {
    let device = 'Desktop';
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        device = 'Mobile';
    }
    return device;
};

export const getPathFromURL = (url: string | undefined): string | undefined => {
    if (url) {
        const urlObject = new URL(url);
        return `${urlObject.pathname}${urlObject.search}`;
    }
    return;
};

export function getYouTubeVideIdFromLink(url: string) {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[7].length === 11 ? match[7] : false;
}
