import { BaseModel } from 'src/app/_common/base-model';
import { ErrorStateMatcher } from '@angular/material/core';
import { AbstractControl } from '@angular/forms';
import { QuestionnaireValidateService } from 'src/app/_services/questionnaire-validate.service';
import { forkJoin, ObservableInput } from 'rxjs';
import { extractTextInCurlyBraces, replaceTextInCurlyBraces } from 'src/app/_common/app-utils';
import { formatNumber } from 'node_modules/@angular/common';

export enum QuestionType {
    single = 'single',
    manySingle = 'many-single',
    multiple = 'multiple',
    text = 'text',
    range = 'range',
    location = 'location',
    financingProposals = 'financing-proposals',
    financingPlot = 'financing-plot',
    financingPerson = 'financing-person',
    financingSharedHousehold = 'financing-shared-household',
}

export enum ValidationType {
    regex = 'REGEX',
    postalCode = 'POSTAL_CODE',
}

export const questionTypeAutoProgress: string[] = [QuestionType.single, QuestionType.manySingle];

export class Control extends BaseModel implements ErrorStateMatcher {
    optionLabel!: string;
    inputValue?: string;
    validation?: Validation[];
    controls?: Control[];
    range?: {
        min: {
            optionLabel: string;
            inputValue: number;
        };
        max: {
            optionLabel: string;
            inputValue: number;
        };
    };
    errors: Validation[] = [];
    apiValidationInProgress = false;

    getErrors(): Validation[] | undefined {
        return this.errors;
    }

    isErrorState(control: AbstractControl | null): boolean {
        return !!this.errors.length && !!(control?.dirty || control?.touched);
    }
}

export interface Validation {
    type: ValidationType;
    pattern?: string;
    validationUrl?: string;
    errorMsg?: string;
}

export class SubQuestion extends BaseModel {
    constructor(subQuestion: SubQuestion) {
        super(subQuestion);
        this.questions = this.questions.map((question) => new Question(question));
    }

    answeredValuesCondition!: string[];
    questions!: Question[];
}

export class Banner extends BaseModel {
    type!: string;
    data: any;
}

export class Question extends BaseModel {
    constructor(question: Question) {
        super(question);
        return this.deserialize(question);
    }

    banner?: Banner;
    questionId!: string;
    questionLabel!: string;
    questionDescription?: string;
    buttonLabel?: string;
    questionTooltip!: string;
    questionTooltipTitle?: string;
    questionType!: QuestionType;
    required!: boolean;
    controls!: Control[];
    subQuestions?: SubQuestion[];
    answeredValues: string[] = [];
    answeredValuesLabels: string[] = [];
    answeredObject: { [key: string]: unknown } = {};
    currentMatchingSubQuestionIndex = -1;
    hasErrorsValue = false;
    apiValidationInProgress = false;
    skip!: boolean;

    deserialize(question: Question): this {
        super.deserialize(question);
        this.currentMatchingSubQuestionIndex = this.currentMatchingSubQuestionIndex ?? -1;
        this.controls = this.controls?.map((control) => new Control(control));
        this.subQuestions = this?.subQuestions?.map((subQuestion) => new SubQuestion(subQuestion));
        if (question.answeredValues) {
            this.answeredValues = question.answeredValues;
        }
        if (question.answeredObject) {
            this.answeredObject = question.answeredObject;
        }
        return this;
    }

    getMatchingSubQuestions(): SubQuestion[] | undefined {
        return this.subQuestions?.filter((subquestion) => {
            const diff = subquestion.answeredValuesCondition.filter((conditionElement) => !this.answeredValues.includes(conditionElement));
            return !diff.length && subquestion;
        });
    }

    getMatchingSubQuestionQuestions(): Question[] | undefined {
        return this.getMatchingSubQuestions()?.[0]?.questions;
    }

    getNextMatchingSubQuestionQuestion(): Question | undefined {
        return this.getMatchingSubQuestionQuestions()?.[++this.currentMatchingSubQuestionIndex];
    }

    getPreviousMatchingSubQuestionQuestion(): Question | undefined {
        return this.getMatchingSubQuestionQuestions()?.[--this.currentMatchingSubQuestionIndex];
    }

    hasErrors(questionnaireValidateService: QuestionnaireValidateService, controlIndex: number): void {
        const control = this.controls[controlIndex];
        control.errors = [];
        this.hasErrorsValue = false;

        const hasRegexErrors = !!control.validation?.find((validation) => {
            if (!new RegExp(validation.pattern || '').test(this.answeredValues[controlIndex] || '')) {
                control.errors.push(validation);
                return true;
            } else {
                return false;
            }
        });

        if (hasRegexErrors) {
            this.hasErrorsValue = true;
            return;
        }

        const apiValidationRequests: { request: ObservableInput<{ apiResponse: any; control: Control; validator: Validation }> }[] = [];
        control.validation
            ?.filter((validation) => validation.type === ValidationType.postalCode)
            .map((validation) => {
                if (validation.validationUrl) {
                    apiValidationRequests.push({
                        request: questionnaireValidateService.validate(validation, this.answeredValues[controlIndex], control),
                    });
                }
            });

        if (apiValidationRequests.length) {
            control.apiValidationInProgress = this.apiValidationInProgress = true;
            forkJoin(apiValidationRequests.map((apiValidationRequest) => apiValidationRequest.request))
                .subscribe({
                    next: (responses: { apiResponse: { valid: boolean; message: string }; control: Control; validator: Validation }[]) => {
                        responses.map((response) => {
                            if (!response.apiResponse.valid) {
                                response.control.errors.push(response.validator);
                                this.hasErrorsValue = true;
                            }
                        });
                    },
                })
                .add(() => {
                    control.apiValidationInProgress = this.apiValidationInProgress = false;
                });
        }
    }

    isAnswered(): boolean {
        switch (this.questionType) {
            case QuestionType.single:
            case QuestionType.multiple:
            case QuestionType.range:
                return !!this.answeredValues.length && !!this.answeredValues.find(Boolean);
            case QuestionType.manySingle:
                return this.answeredValues.filter(Boolean).length === this.controls.length;
            case QuestionType.text:
            case QuestionType.location:
                return (
                    this.answeredValues.every((element) => !!element) && this.answeredValues.filter(Boolean).length === this.controls.length
                );
            default:
                return true;
        }
    }

    getQuestionAndAnswerAsString(): QuestionAndAnswers {
        let answers = [];
        const subQuestionsAnswers: QuestionAndAnswers[] = [];
        switch (this.questionType) {
            case QuestionType.single:
            case QuestionType.multiple:
                answers = this.answeredValues.map((answer) => this.controls.find((control) => control.inputValue === answer)?.optionLabel);
                break;
            case QuestionType.text:
            case QuestionType.range:
                answers = this.answeredValues;
                break;
            case QuestionType.manySingle:
                answers = this.answeredValues.map((answer, index) => {
                    const control = this.controls[index];
                    return `${control.optionLabel}: ${control.controls?.find((val) => val.inputValue === answer)?.optionLabel}`;
                });
                break;
            default:
                answers = [''];
                break;
        }

        this.subQuestions?.map((subQuestion) =>
            subQuestion.questions.map((question) => subQuestionsAnswers.push(question.getQuestionAndAnswerAsString()))
        );

        return new QuestionAndAnswers({
            questionId: this.questionId,
            question: this.questionLabel,
            answers,
            subQuestionsAnswers,
        });
    }

    isValid(): boolean {
        return ((this.required && this.isAnswered()) || !this.required) && !this.hasErrorsValue && !this.apiValidationInProgress;
    }

    isReady(): boolean {
        return questionTypeAutoProgress.includes(this.questionType) && this.isAnswered();
    }

    hasNextSubQuestion(): boolean {
        return !!this.getMatchingSubQuestionQuestions()?.[this.currentMatchingSubQuestionIndex + 1];
    }

    hasPrevSubQuestion(): boolean {
        return !!this.getMatchingSubQuestionQuestions()?.[this.currentMatchingSubQuestionIndex - 1];
    }
}

export class QuestionAndAnswers extends BaseModel {
    questionId?: string;
    question!: string;
    answers!: string[];
    subQuestionsAnswers?: QuestionAndAnswers[];
}

export class Group extends BaseModel {
    constructor(group: Group) {
        super(group);
        this.questions = this.questions.map((question) => new Question(question));
    }

    groupName!: string;
    questions!: Question[];
}

export class Questionnaire extends BaseModel {
    constructor(data?: Questionnaire) {
        super(data);
        return this.deserialize(data);
    }

    static BUDGET_QUESTION_ID = 'budget';
    static FINANCING_QUESTION_ID = 'financing';
    static FINANCING_SUPPORT_QUESTION_ID = 'financing.support';

    static questionLabelFormatHelperFunctionNames = {
        localeNumber: 'localeNumber',
    };

    id = '';
    status: 'IN_PROGRESS' | 'COMPLETED' = 'IN_PROGRESS';
    version!: string;
    groups!: Group[];
    currentQuestionIndex = 0;
    currentQuestion!: Question;

    static isValidQuestionnaire(questionnaireData: any) {
        return !!questionnaireData?.[`version`];
    }

    getQuestionLabelFormatHelper(): { [key: string]: (value: string | number) => string } {
        return {
            [Questionnaire.questionLabelFormatHelperFunctionNames.localeNumber](value: string | number): string {
                return formatNumber(Number(value), 'de-DE', '1.0-0');
            },
        };
    }

    deserialize(input: any): this {
        super.deserialize(input);
        this.groups = this.groups?.map((group) => new Group(group));

        const currentParentQuestion = this.getQuestion(this.currentQuestionIndex);

        if (this.currentQuestion && currentParentQuestion.currentMatchingSubQuestionIndex === -1) {
            // If there is current question, and it is a top level question
            this.currentQuestion = this.getQuestion(this.currentQuestionIndex).deserialize(this.currentQuestion);
        } else if (this.currentQuestion && currentParentQuestion.currentMatchingSubQuestionIndex !== -1) {
            // If there is current question, and it is a sub question
            this.currentQuestion =
                this.getQuestion(this.currentQuestionIndex)?.getMatchingSubQuestionQuestions()?.[
                    currentParentQuestion.currentMatchingSubQuestionIndex
                ] || currentParentQuestion;
        } else {
            this.currentQuestion = this.getQuestion(this.currentQuestionIndex);
        }
        return this;
    }

    setAnswers(answers: { questionId: string; answeredValues: string[]; skip: string }[]) {
        answers.forEach((answer) => {
            const question = this.getQuestionById(answer.questionId);
            if (question) {
                question.answeredValues = answer.answeredValues;
                question.skip = answer.skip === 'true';
            }
            if (this.currentQuestion.skip) {
                this.goToQuestion(0);
            }
        });
    }

    goToQuestion(index: number) {
        this.currentQuestion = this.getQuestion(index);
        this.currentQuestionIndex = index;
    }

    getQuestionsCount(): number {
        return this.groups?.reduce((count, current) => count + current.questions.filter((question) => !question.skip).length, 0);
    }

    getQuestion(index: number): Question {
        return this.groups?.flatMap((group) => group.questions).filter((question) => !question.skip)[index];
    }

    getAllQuestions(): Question[] {
        return this.groups?.flatMap((group) => group.questions);
    }

    getQuestionById(id: string): Question | undefined {
        return this.groups?.flatMap((group) => group.questions).find((question) => question.questionId === id);
    }

    nextQuestion(): void {
        this.currentQuestion =
            this.getQuestion(this.currentQuestionIndex).getNextMatchingSubQuestionQuestion() ||
            this.getQuestion(++this.currentQuestionIndex);
    }

    previousQuestion(): void {
        const curQuestion = this.getQuestion(this.currentQuestionIndex);
        if (curQuestion.currentMatchingSubQuestionIndex >= 0) {
            this.currentQuestion = curQuestion.getPreviousMatchingSubQuestionQuestion() || curQuestion;
        } else {
            const prevQuestion = this.getQuestion(--this.currentQuestionIndex);
            this.currentQuestion = prevQuestion.getPreviousMatchingSubQuestionQuestion() || prevQuestion;
        }
    }

    getQuestionLabel(question: Question): string {
        const templateVariable = extractTextInCurlyBraces(question.questionLabel)?.split(':');
        if (templateVariable) {
            const [relatedQuestionId, formattingFunctionName] = templateVariable;
            const answerForTemplate = this.getQuestionById(relatedQuestionId)?.getQuestionAndAnswerAsString().answers.join(', ') || '';
            const formattingFunction = formattingFunctionName && this.getQuestionLabelFormatHelper()[`${formattingFunctionName}`];
            const replaceValue = formattingFunction ? formattingFunction(answerForTemplate) : answerForTemplate;
            return replaceTextInCurlyBraces(question.questionLabel, replaceValue);
        } else {
            return question.questionLabel;
        }
    }

    getQuestionDescription(question: Question): string {
        return question.questionDescription || '';
    }

    getCurrentQuestionNumber(): number {
        return this.currentQuestionIndex + 1;
    }

    getCurrentQuestion(): Question {
        return this.currentQuestion;
    }

    getCurrentGroup(): Group | undefined {
        return this.groups.find((group) => group.questions.includes(this.getQuestion(this.currentQuestionIndex)));
    }

    hasPreviousQuestion(): boolean {
        return (
            !!this.getQuestion(this.currentQuestionIndex - 1) ||
            this.getQuestion(this.currentQuestionIndex).currentMatchingSubQuestionIndex >= 0
        );
    }

    hasNextQuestion(): boolean {
        return !!this.getQuestion(this.currentQuestionIndex + 1) || this.getQuestion(this.currentQuestionIndex).hasNextSubQuestion();
    }

    financingRequested(): boolean {
        let financingRequested = false;
        const financingQuestion = this.getQuestionById(Questionnaire.FINANCING_QUESTION_ID);
        financingQuestion?.subQuestions?.forEach((subQuestion) => {
            const financingSupportQuestion = subQuestion.questions.find(
                (q) => q.questionId === Questionnaire.FINANCING_SUPPORT_QUESTION_ID
            );
            if (financingSupportQuestion && financingSupportQuestion.answeredValues.includes('1')) {
                financingRequested = true;
            }
        });
        return financingRequested;
    }

    getCombinedAnswersObject() {
        const combinedAnswers = {};
        this.getAllQuestions()?.forEach((question: Question) => {
            return Object.assign(combinedAnswers, question.answeredObject);
        });
        return combinedAnswers;
    }
}

export class QuestionnaireAnswers extends BaseModel {
    version!: string;
    groups!: GroupAnswer[];
}

export class GroupAnswer extends BaseModel {
    constructor(group: Group) {
        super(group);
        this.questions = this.questions.map((question) => new QuestionAnswer(question));
    }

    questions!: QuestionAnswer[];
}

export class QuestionAnswer extends BaseModel {
    answeredValues!: string[];
    questionId!: string;
    subQuestions?: SubQuestionAnswer;
}

export class SubQuestionAnswer extends BaseModel {
    constructor(subQuestionAnswer: SubQuestionAnswer) {
        super(subQuestionAnswer);
        this.questions = this.questions.map((questionAnswer) => new QuestionAnswer(questionAnswer));
    }

    answeredValuesCondition!: string[];
    questions!: QuestionAnswer[];
}
