import { Component } from '@angular/core';
import { UserService } from 'src/app/_services/user.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { JourneyBpService } from '../../_business-processes/journey.bp.service';
import { Journey } from '../../_models/journey';

@Component({
    selector: 'app-unclosable-social-login-prompt',
    templateUrl: './unclosable-social-login-prompt.component.html',
    styleUrls: ['./unclosable-social-login-prompt.component.scss'],
})
export class UnclosableSocialLoginPromptComponent {
    journey: Journey;
    journeyStage: string | undefined;
    journeyStageIndex: number | undefined;
    redirectPath = '';

    constructor(
        private userService: UserService,
        private googleAnalyticsService: GoogleAnalyticsService,
        private journeyBpService: JourneyBpService
    ) {
        this.googleAnalyticsService.event('signup_started', 'users', 'house_directory');
        this.journey = journeyBpService.getJourney();
        this.journeyStage = this.journey?.getCurrentStage()?.name;
        this.journeyStageIndex = this.journey?.getCurrentStage()?.position;
    }

    getLoginRegisterPromptVisible() {
        const { visibility, redirectPath } = this.userService.getLoginRegisterPromptVisible();
        this.redirectPath = redirectPath;
        return visibility.unclosablePromptVisible;
    }

    googleLogin() {
        this.userService.login(this.redirectPath || '/users/canvas', '', 'google');
    }

    appleLogin() {
        this.userService.login(this.redirectPath || '/users/canvas', '', 'apple');
    }

    credentialsLogin() {
        this.userService.login(this.redirectPath || '/users/canvas', 'email');
    }
}
