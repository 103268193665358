<app-modal id="matches-preview-register-prompt">
    <div class="modal-container">
        <div class="logo"></div>
        <div class="title">{{ 'matches-preview.register-prompt.title' | translate }}</div>
        <div class="message">
            <ol *ngIf="userType !== userTypeEnum.gambler && userType !== userTypeEnum.earlyDreamer">
                <li class="list-item" innerHTML="{{ 'matches-preview.register-prompt.builder.line1' | translate }}"></li>
                <li class="list-item" innerHTML="{{ 'matches-preview.register-prompt.builder.line2' | translate }}"></li>
                <li class="list-item" innerHTML="{{ 'matches-preview.register-prompt.builder.line3' | translate }}"></li>
            </ol>
            <ol *ngIf="userType === userTypeEnum.gambler || userType === userTypeEnum.earlyDreamer">
                <li class="list-item" innerHTML="{{ 'matches-preview.register-prompt.gambler.line1' | translate }}"></li>
                <li class="list-item" innerHTML="{{ 'matches-preview.register-prompt.gambler.line2' | translate }}"></li>
                <li class="list-item" innerHTML="{{ 'matches-preview.register-prompt.gambler.line3' | translate }}"></li>
            </ol>
        </div>
        <div class="buttons">
            <button mat-flat-button color="primary" (click)="login()">
                {{ 'matches-preview.register-prompt.button-title' | translate }}
            </button>
        </div>
        <div class="conditions">
            {{ 'matches-preview.register-prompt.cashback-conditions.text' | translate }}
            <a href="https://www.euerzuhause.de/teilnahmebedingungen/bauleistungsversicherung" target="_blank">{{
                'matches-preview.register-prompt.cashback-conditions.link' | translate
            }}</a>
        </div>
    </div>
</app-modal>
