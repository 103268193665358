import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DataService } from 'src/app/_services/data.service';

@Injectable({
    providedIn: 'root',
})
export class PhoneValidationService {
    constructor(private dataService: DataService) {}

    validate(phone: string) {
        const { url, apiKey } = environment.phoneVerification;
        return this.dataService.get(`${url}/v1/validate/${phone}?apikey=${apiKey}`).pipe(map((response) => response.valid));
    }
}
