import { Component, Input } from '@angular/core';
import { UserService } from 'src/app/_services/user.service';
import { ActivatedRoute } from '@angular/router';
import { UserType } from 'src/app/_models/matches-summary';

@Component({
    selector: 'app-matches-preview-register-prompt',
    templateUrl: './matches-preview-register-prompt.component.html',
    styleUrls: ['./matches-preview-register-prompt.component.scss'],
})
export class MatchesPreviewRegisterPromptComponent {
    @Input() userType: string | undefined = '';
    userTypeEnum = UserType;

    constructor(
        private userService: UserService,
        private activatedRoute: ActivatedRoute
    ) {}

    login() {
        const redirectUrl = `/matches/matches/${this.activatedRoute.snapshot.paramMap.get('id')}`;
        this.userService.login(redirectUrl, 'email');
    }
}
