<div class="secondary-navigation">
    <div class="user-location">
        <a routerLink="/intro" routerLinkActive="active">
            <mat-icon>interests</mat-icon>
            Home
        </a>
        <a routerLink="/users/canvas" routerLinkActive="active">
            <mat-icon>polyline</mat-icon>
            Journey
        </a>
        <a routerLink="/tools/concierge" routerLinkActive="active">
            <mat-icon>room_service</mat-icon>
            Services
        </a>
        <a routerLink="/suche" routerLinkActive="active">
            <mat-icon>cottage</mat-icon>
            Houses
        </a>
    </div>
</div>
