<footer class="footer" [class]="path">
    <div class="footer-container">
        <img
            loading="lazy"
            alt="ezh Logo"
            width="232"
            src="https://assets-global.website-files.com/64f1d12ffceea2659abfad5f/650199ceef6868a6c00dd762_ezh-logo-min.svg"
            class="footer-logo-img hide-on-mobile"
        />
        <div class="footer-links">
            <div class="footer-block">
                <div class="footer-navigation-title">Unsere Services</div>
                <a href="https://www.euerzuhause.de/baupartner-werden">Baupartner werden</a>
                <a routerLink="/suche/slim">Häuserverzeichnis</a>
                <a routerLink="/questionnaire/slim">Hausbauguide</a>
                <a href="https://www.euerzuhause.de/vergleichsangebot">Vergleichsangebot</a>
            </div>
            <div class="footer-block">
                <div class="footer-navigation-title">Über uns</div>
                <a href="https://www.euerzuhause.de/datenschutz">Datenschutz</a>
                <a href="https://www.euerzuhause.de/agbs">AGBs</a>
                <a href="https://www.euerzuhause.de/impressum">Impressum</a>
                <a href="https://www.euerzuhause.de/jobs">Jobs</a>
            </div>
        </div>
    </div>
    <div class="sub-footer">
        <div class="footer-social-block">
            <a href="https://www.instagram.com/euerzuhause/" target="_blank">
                <img
                    src="https://assets-global.website-files.com/64f024ff563c48d7da55cdcf/64f6ed0759b07b13ac663bb5_Instagram-min.svg"
                    loading="lazy"
                    alt="instagram"
                />
            </a>
            <a href="https://www.facebook.com/euerzuhause" target="_blank">
                <img
                    src="https://assets-global.website-files.com/64f024ff563c48d7da55cdcf/64f6ed0581f2e89ddfdfce57_facebook-min.svg"
                    loading="lazy"
                    alt="facebook"
                />
            </a>
            <a href="https://www.tiktok.com/@euerzuhause" target="_blank">
                <img
                    src="https://assets-global.website-files.com/64f024ff563c48d7da55cdcf/66042ca800a4155aa742e96b_%23505d68.svg"
                    loading="lazy"
                    alt="TikTok"
                />
            </a>
            <a href="https://www.linkedin.com/company/euerzuhause/?originalSubdomain=de" target="_blank">
                <img
                    src="https://assets-global.website-files.com/64f024ff563c48d7da55cdcf/64f6a978afa93a468659cb0b_linkedin.svg"
                    loading="lazy"
                    alt="linkedin"
                />
            </a>
        </div>
        <div class="footer-copyright-center">2024 EuerZuhause | Alle Rechte vorbehalten</div>
    </div>
</footer>
